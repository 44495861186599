export const FAQ_DATA = [
  {
    topic: "Is TransferItNow free to use service?",
    details: "Yes, TransferItNow is a free to use service as of now.",
  },
  {
    topic: "Why there are ads on the TransferItNow?",
    details: "The ads helps us keeping TransferItNow service as free to use.",
  },
  {
    topic: "Do we provide any paid services?",
    details:
      "In the current state we don't provide paid services. If any individual/company require us to provide some additional features for their specific use case then we may think about it adding them for free or may become an optional paid feature.",
  },
  {
    topic: "Why do we need to register?",
    details:
      "We want to make it secured, reliable and robust for sending personal files or professional files with proper feature to know status of files sent too.",
  },
  // { topic: "", details: "" },
];
