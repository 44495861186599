import {
  DIALOG_HIDE,
  DIALOG_REMOVE_VALUE,
  DIALOG_SHOW,
  DIALOG_VALUE,
} from "../actionTypes/dialog";

export const showDialog = () => async (dispatch) => {
  dispatch({
    type: DIALOG_SHOW,
  });
};
export const hideDialog = () => async (dispatch) => {
  //console.log({}, "payload");
  dispatch({
    type: DIALOG_HIDE,
    payload: {},
  });
};

export const addDialogValue = (value) => async (dispatch) => {
  //console.log({ value }, "payload");
  dispatch({
    type: DIALOG_VALUE,
    payload: value,
  });
};
export const removeDialogValue = () => async (dispatch) => {
  //console.log({}, "payload");
  dispatch({
    type: DIALOG_REMOVE_VALUE,
    payload: {},
  });
};
