import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";

// components
import Navbar from "../Navbar";
import Footer from "../privateLayout/Footer";
import { hideLoader, hideMessage } from "../../appRedux/actions/common";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  //console.log("----------------LoginLayout-------------------");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { url, accessToken, member } = useSelector((state) => ({
    url: state.common.initURL,
    accessToken: state.auth.accessToken,
    member: state.auth.member,
  }));
  useEffect(() => {
    //console.log({ url }, "useEffect");
    if (accessToken && member && member._id) {
      navigate("/transferit", { replace: true });
    } else if (url) {
      navigate(url);
    }
  }, [accessToken]);
  return (
    <>
      <div className="absolute w-full z-20">
        <Navbar />
      </div>
      <RouterLink to="/">{/* <Logo /> */}</RouterLink>
      <Outlet />
      <Footer />
    </>
  );
}
