import {
  SIGNIN_MEMBER_ERROR,
  SIGNIN_MEMBER_SUCCESS,
  SIGNUP_MEMBER_SUCCESS,
  SIGNOUT_AUTH_MEMBER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
} from "../actionTypes/auth";
import { STORAGE_INFO_SUCCESS } from "../actionTypes/storage";

export const AUTH_INIT_STATE = {
  authLevel: 1,
  tempInfo: {},
  storage: JSON.parse(localStorage.getItem("storagePlan")) || {},
  member: JSON.parse(localStorage.getItem("member")) || {},
  accessToken: localStorage.getItem("accessToken"),
  routeLevel: JSON.parse(localStorage.getItem("member"))
    ? JSON.parse(localStorage.getItem("member")).accessLevel
    : 0 || 0,
};

function AuthReducer(state = AUTH_INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LEVEL: {
      return {
        ...state,
        authLevel: payload,
      };
    }

    case SIGNIN_MEMBER_SUCCESS: {
      state.initURL = "/"; // state.member && state.member.trainee? "/trainee":"/main/dashboard/crm"
      //console.log({ payload });
      return {
        ...state,
        member: payload.data,
        storage: payload.storagePlan,
        accessToken: payload.authToken,
        routeLevel: payload.data.accessLevel,
      };
    }
    case SIGNUP_MEMBER_SUCCESS: {
      state.initURL = "/"; // state.member && state.member.trainee? "/trainee":"/main/dashboard/crm"
      //console.log({ payload });
      return {
        ...state,
        tempInfo: payload.data,
        // accessToken: payload.authToken,
        // routeLevel: payload.data.accessLevel
      };
    }

    case SIGNIN_MEMBER_ERROR: {
      //console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: "error",
        message: payload.memberMessage,
        status: payload.status,
        errorMessage: payload.memberMessage,
      };
    }

    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      // //console.log({
      //   payload,
      //   SIGNOUT_AUTH_MEMBER_SUCCESS: "SIGNOUT_AUTH_MEMBER_SUCCESS",
      // });
      return {
        undefined,
      };
    }

    case ALL_CLEAR_SUCCESS: {
      return {
        ...state,
        member: {},
        accessToken: null,
        routeLevel: 0,
      };
    }
    case STORAGE_INFO_SUCCESS: {
      return {
        ...state,
        storage: payload.data,
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
