import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
// material
// import { experimentalStyled as styled } from '@material-ui/styles';
// components
import Navbar from "./Navbar";
import Container from "./Container";
import Page from "./Page";
import Footer from "./Footer";

import { hideLoader, hideMessage } from "../../appRedux/actions/common";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  //console.log("----------------LoginLayout-------------------");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showMessage, tempInfo, url, loader, accessToken, member } = useSelector((state) => ({
    showMessage: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
    loader: state.common.loader,
    url: state.common.initURL,
    accessToken: state.auth.accessToken,
    member: state.auth.member,
    tempInfo: state.auth.tempInfo,
  }));
  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    // console.log({ url, tempInfo }, "------------------");
    // if (loader) dispatch(hideLoader());
    if (accessToken && member && member._id && member.isEmailVerified)
      navigate("/transferit", { replace: true });
    else if (accessToken && member && member._id) {
      navigate(url ? url : "/transferit", { replace: true });
    } else if (url) {
      const timeout = setTimeout(() => {
        navigate(url, { replace: true });
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [accessToken, tempInfo]);
  return (
    <Page>
      <Navbar />
      <Container>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
        <Outlet />
      </Container>
      <Footer />
    </Page>
  );
}
