// import { SIGNIN_MEMBER_ERROR, SIGNIN_MEMBER_SUCCESS } from '../actionTypes/auth';
import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";
import { TRANSFER_SELECTED_RESET } from "../actionTypes/transfer";

import { DIALOG_HIDE, DIALOG_REMOVE_VALUE, DIALOG_SHOW, DIALOG_VALUE } from "../actionTypes/dialog";

const INIT_STATE = {
  open: false,
  value: {},
};

function DialogReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        open: false,
        value: {},
      };
    }
    case DIALOG_SHOW: {
      return {
        ...state,
        open: true,
      };
    }
    case DIALOG_HIDE: {
      return {
        ...state,
        open: false,
        value: {},
      };
    }

    case DIALOG_VALUE: {
      return {
        ...state,
        open: true,
        value: payload,
      };
    }
    case DIALOG_REMOVE_VALUE: {
      return {
        ...state,
        open: false,
        value: {},
      };
    }
    default:
      return state;
  }
}

export default DialogReducer;
