import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import Image from "@material-tailwind/react/Image";
import H3 from "@material-tailwind/react/Heading3";
import Icon from "@material-tailwind/react/Icon";
import { transferRecived } from "../../appRedux/actions/transfer";
import { Avatar } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormView } from ".";

import $ from "jquery";
import { updateUser } from "../../appRedux/actions/user";
import { updateUserProfile } from "../../appRedux/actions/user";
import { apiURL } from "../../utils/config";

export default function Content() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const { analytics, member, loader } = useSelector((state) => ({
    member: state.member.member,
    loader: state.common.loader,
    analytics: state.user.analytics,
  }));
  useEffect(() => {
    if (!loader) {
      setEdit(false);
    }

    //console.log({ member });
  }, [loader, member]);
  const updateUserData = (data) => {
    dispatch(updateUser(data));
  };
  const importFile = (e) => {
    //console.log("hiiiii");
    $("#importFile").val("");
    $("#importFile").trigger("click");
  };
  const changeFile = (e) => {
    e.preventDefault();
    var acceptedFiles = e.target.files[0];
    var form = new FormData();
    form.append("file", acceptedFiles);
    //console.log({ acceptedFiles });
    dispatch(updateUserProfile(form));
    // var result = Array.from(acceptedFiles).reduce(function (r, a) {
    //   let url = a.webkitRelativePath.split("/");
    //   let fileName = url.length > 1 ? url[1] : a.name;
    //   r[fileName] = r[fileName] || [];
    //   r[fileName].push(a);
    //   return r;
    // }, Object.create(null));
    // //console.log({ acceptedFiles, result });
    // dispatch(setFileData(result));

    //

    // async.mapSeries(
    //   Object.keys(result),
    //   function (info, next) {
    //     let isFolder = info.split(".").length > 1 ? false : true;
    //     let fileInfo = isFolder ? {} : result[info][0];
    //     let size = isFolder
    //       ? result[info].reduce((accumulator, object) => {
    //           return accumulator + object.size;
    //         }, 0)
    //       : fileInfo.size;

    //     next(null, {
    //       name: info,
    //       type: fileInfo.type,
    //       size: size,
    //       // bucket: Math.random(),
    //       alteredName:
    //         new Date().getTime() +
    //         "_" +
    //         Math.random().toString().split(".").pop() +
    //         "." +
    //         info.split(".").pop(),
    //       files: result[info][0],
    //       numberOfFile: result[info].length,
    //     });
    //   },
    //   function (err, results) {
    //     //console.log({ err, results });
    //     // callback(err, listSuccess);

    //     // dispatch(setFileData(results));
    //     // // navigate("/transferit");
    //     // handleClose();
    //   }
    // );
    // handleClose();
  };
  return (
    <section className="relative py-16 bg-gray-100 ">
      <div className="container max-w-7xl px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
          <div className="px-6">
            <div className=" absolute right-0 ">
              <Button
                color="transparent"
                className="py-2.5 px-2.5  ml-3 text-xl flex items-center text-white capitalize "
                ripple="light"
                buttonType="link"
                onClick={() => setEdit((pvrEdit) => !pvrEdit)}
              >
                <Icon
                  name={`${edit ? "close" : "edit"}`}
                  color={`${edit ? "blueGray" : "orange"}`}
                  size="xl"
                />
              </Button>
            </div>
            <div className="flex flex-wrap justify-center">
              <div className="w-full md:w-3/12 px-4 mx-auto flex justify-center lg:mt-0">
                <div className="relative">
                  <div className="w-40 mt-8 ">
                    <Image
                      src={apiURL + member.profilePicUrl}
                      alt="Profile picture"
                      raised
                      rounded
                      className="w-200 view-avatar"
                      style={{ height: "10rem", width: "10rem" }}
                    />
                    <div className="absolute top-[2rem] h-[10rem] set-avatar">
                      <Avatar
                        sx={{
                          width: "10rem",
                          height: "10rem",
                          cursor: "pointer",
                          backgroundColor: "#b4b4b4c9",
                        }}
                        onClick={(e) => importFile()}
                      >
                        <Icon name="photo_camera" color="lightBlue" size="4xl" />
                      </Avatar>
                    </div>
                    <input
                      type="file"
                      id="importFile"
                      // name="files[]"
                      onChange={changeFile}
                      style={{ display: "none" }}
                      multiple={false}
                    />
                  </div>
                </div>
              </div>
              {edit ? (
                <div className="w-full md:w-4/12 px-4 mx-auto justify-center mt-4  ">
                  <FormView member={member} updateUserData={updateUserData} />
                </div>
              ) : (
                <div className="w-full md:w-4/12 px-4 mx-auto  mt-12  ">
                  <div className="w-full   flex justify-center    ">
                    <div className="text-center mt-1 mb-10">
                      <div>
                        <H3 color="gray">{member.name}</H3>
                        {member.email && member.email !== "" && (
                          <div className="mt-0 mb-2 text-gray-700 font-medium flex items-center justify-center gap-2">
                            <Icon name="mail" size="xl" />
                            {member.email}
                          </div>
                        )}
                        {member.companyName && member.companyName !== "" && (
                          <div className="mb-2 text-gray-700 mt-4 flex items-center justify-center gap-2">
                            <Icon name="work" size="xl" />
                            {member.companyName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full md:w-5/12 px-4 mx-auto flex justify-center mt-6 mb-4  ">
                <div className="flex justify-center py-4  mx-4   mb-4">
                  <div className=" p-4 text-center">
                    {/* <Icon name="send_and_archive" size="xl"/> */}
                    <Button
                      className="py-1 px-3.5 text-sm flex items-center text-white capitalize "
                      size="sm"
                      color="teal"
                      buttonType="Filled"
                      ripple="light"
                      onClick={() => {
                        //console.log("contacts");
                        navigate("/contacts");
                      }}
                    >
                      <Icon name="contacts" size="sm" color="white" />
                      <span className="   font-medium text-sm capitalize">Contacts</span>
                    </Button>
                    <span className="text-4xl mt-2  p-4  block uppercase tracking-wide text-gray-900">
                      {analytics.totalContact}
                    </span>
                    {/* <span className="text-sm text-gray-700"></span> */}
                  </div>
                  <div className="  p-4 text-center">
                    <Button
                      className="py-1 px-3.5 text-sm flex items-center text-white capitalize "
                      size="sm"
                      color="amber"
                      buttonType="Filled"
                      ripple="light"
                      onClick={() => {
                        //console.log("history");
                        dispatch(transferRecived(false));
                        navigate("/history");
                      }}
                    >
                      <Icon name="send" size="sm" color="white" />
                      <span className="   font-medium text-sm capitalize">Send</span>
                    </Button>
                    {/* <Icon name="send_and_archive" size="xl" className="p-2" />
                    <span className="text-sm text-gray-700 p-2">Sent</span> */}
                    <span className="text-4xl mt-2 p-4   block uppercase tracking-wide text-gray-900">
                      {analytics.totalSend}
                    </span>
                  </div>
                  <div className=" p-4 text-center">
                    <Button
                      className="py-1 px-3.5 text-sm flex items-center text-white capitalize "
                      size="sm"
                      color="lightGreen"
                      buttonType="Filled"
                      ripple="light"
                      onClick={() => {
                        //console.log("history");
                        dispatch(transferRecived(true));
                        navigate("/history");
                      }}
                    >
                      <Icon name="send_and_archive" size="sm" color="white" />
                      <span className="   font-medium text-sm capitalize">Received</span>
                    </Button>
                    {/* <span className="text-sm text-gray-700">
                      <Icon name="send_and_archive" size="xl" /> Received
                    </span> */}
                    <span className="text-4xl mt-2  p-4 block uppercase tracking-wide text-gray-900">
                      {analytics.totalReceived}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
