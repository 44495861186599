import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useN } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
// import Avatar from "@material-tailwind/react/Av";
// import { Avatar } from "@material-tailwind/react";
import Dropdown from "@material-tailwind/react/Dropdown";
import DropdownItem from "@material-tailwind/react/DropdownItem";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";
import { memberSignOut } from "../appRedux/actions/auth";

import { CardImage, Image } from "@material-tailwind/react";
import Logo from "../assets/img/logo-1600x400.png";
import { transferRecived, resetSelectedDataTransfers } from "../appRedux/actions/transfer";
import { initURL, showLoader } from "../appRedux/actions/common";
import { apiURL } from "../utils/config";
import { Avatar } from "@material-ui/core";
import LogoutDialog from "../components/LogoutDialog";
export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  //console.log("href", { location });
  const { pathname } = location;
  const { accessToken, member } = useSelector((state) => ({
    accessToken: state.auth.accessToken,
    member: state.member.member,
  }));

  const handleClickOpen = () => {
    setOpen(true);
    // setOpenNavbar(!openNavbar);
    // dispatch(initURL("/home"));
    // dispatch(memberSignOut());
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    dispatch(showLoader());
    // dispatch(memberSignOut());
    setOpen(false);

    setOpenNavbar(!openNavbar);
    dispatch(initURL("/home"));
    dispatch(memberSignOut());
  };
  return (
    <Navbar color="transparent" navbar className={` navbar-bg-color ${openNavbar ? "dark" : ""}`}>
      <NavbarContainer>
        <NavbarWrapper className="navbar-wrapper">
          <Link to="/home" className="capitalize">
            <CardImage
              src={Logo}
              className=" pt-10"
              style={{ boxShadow: "none", width: "200px" }}
            />
          </Link>

          {member && accessToken && (
            <div className="text-white items-center profile-nav-top">
              <Button
                color="transparent"
                size="sm"
                buttonType="link"
                className="text-xl flex items-center text-center"
                ripple="light"
                onClick={() => {
                  setOpenNavbar(!openNavbar);
                  navigate("/profile");
                }}
              >
                <div className="py-2.5 px-2.5 font-medium flex items-center">
                  {member.profilePicUrl && member.profilePicUrl !== "" ? (
                    <Avatar
                      alt="Profile picture"
                      src={apiURL + member.profilePicUrl}
                      sx={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <Avatar sx={{ width: 24, height: 24 }}>
                      <Icon name="person" size="2xl" color={"white"} />
                    </Avatar>
                  )}
                  {/* <span className={`pt-1  `}>{member.name}</span> */}
                </div>
              </Button>
            </div>
          )}
          <NavbarToggler onClick={() => setOpenNavbar(!openNavbar)} color="white" />
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav>
            {member && accessToken ? (
              <div className="flex flex-col z-50 lg:flex-row lg:items-center">
                <div className="px-4 items-center">
                  <Button
                    className="py-2 px-2.5 text-xl flex items-center text-white capitalize w-full"
                    size="2xl"
                    buttonType="button"
                    ripple="light"
                    onClick={() => {
                      setOpenNavbar(!openNavbar);
                      dispatch(initURL(""));
                      dispatch(resetSelectedDataTransfers());
                      navigate("/transferit");
                    }}
                  >
                    <Icon name="add" size="2xl" color="white" />
                    <span className="   font-medium text-sm capitalize">Transfer Now</span>
                  </Button>
                </div>
                <Button
                  color="transparent"
                  className="py-2.5 px-2.5    text-xl flex items-center text-white capitalize"
                  //   size="sm"
                  size="2xl"
                  buttonType="button"
                  ripple="light"
                  onClick={() => {
                    //console.log("history");
                    setOpenNavbar(!openNavbar);
                    dispatch(transferRecived(false));
                    navigate("/history");
                  }}
                >
                  <span className="pt-1 mt-1">
                    <Icon
                      name="work_history"
                      size="2xl"
                      color={pathname.includes("/history") ? "lightBlue" : "white"}
                    />
                  </span>
                  <span
                    className={`pt-1 font-medium text-sm capitalize ${
                      pathname.includes("/history") ? "text-light-blue-500" : ""
                    } `}
                  >
                    History
                  </span>
                </Button>

                <Button
                  color="transparent"
                  className="py-2.5 px-2.5  text-xl flex items-center text-white capitalize"
                  size="sm"
                  // size="xl"
                  buttonType="button"
                  ripple="light"
                  onClick={() => {
                    setOpenNavbar(!openNavbar);
                    navigate("/contacts");
                  }}
                >
                  <span className="pt-1 mt-1">
                    <Icon
                      name="contacts"
                      size="2xl"
                      color={pathname.includes("/contacts") ? "lightBlue" : "white"}
                    />
                  </span>
                  <span
                    className={`pt-1 font-medium text-sm capitalize ${
                      pathname.includes("/contacts") ? "text-light-blue-500" : ""
                    } `}
                  >
                    Contacts
                  </span>
                </Button>

                <Button
                  color="red"
                  className="py-2.5 px-2.5  text-xl flex items-center text-white capitalize logout"
                  size="sm"
                  buttonType="link"
                  ripple="light"
                  onClick={() => {
                    // setOpenNavbar(!openNavbar);
                    // dispatch(initURL("/home"));
                    // dispatch(memberSignOut());
                    handleClickOpen();
                  }}
                >
                  <span className="pt-3 text-2xl mt-1 span">
                    <Icon name="logout" size="2xl" color={"red"} />
                  </span>
                  <span className={`pt-1 font-medium text-sm capitalize`}>Logout</span>
                </Button>
                <div className="text-white items-center profile-nav">
                  <Dropdown
                    color="transparent"
                    size="sm"
                    buttonType="link"
                    buttonText={
                      <div className="py-2.5 px-2.5 font-medium flex items-center">
                        <Icon name="person" size="2xl" color={"white"} />
                        <span className={`pt-1  `}>{member.name}</span>
                      </div>
                    }
                    className="text-xl flex items-center text-center"
                    ripple="light"
                  >
                    <Link to="/profile">
                      <DropdownItem color="lightBlue">Profile</DropdownItem>
                    </Link>
                    <DropdownItem
                      color="red"
                      onClick={() => {
                        // setOpenNavbar(!openNavbar);
                        // dispatch(initURL("/home"));
                        // dispatch(memberSignOut());
                        handleClickOpen();
                      }}
                    >
                      Logout
                    </DropdownItem>
                    {/* </Link> */}
                  </Dropdown>
                </div>
              </div>
            ) : (
              <div className="flex flex-col z-50 lg:flex-row lg:items-center">
                <Button
                  color="transparent"
                  className="py-2.5 px-2.5 text-xl flex items-center text-white capitalize"
                  //   size="sm"
                  size="2xl"
                  buttonType="button"
                  ripple="light"
                  onClick={() => {
                    setOpenNavbar(!openNavbar);
                    navigate("/home");
                  }}
                >
                  <Icon
                    name="home"
                    size="2xl"
                    color={pathname.includes("/home") ? "lightBlue" : "white"}
                  />

                  <span
                    className={`pt-1 font-medium text-sm capitalize ${
                      pathname.includes("/home") ? "text-light-blue-500" : ""
                    } `}
                  >
                    Home
                  </span>
                </Button>
                <Button
                  color="transparent"
                  className="py-2.5 px-2.5 text-xl flex items-center text-white capitalize"
                  //   size="sm"
                  size="2xl"
                  buttonType="button"
                  ripple="light"
                  onClick={() => {
                    setOpenNavbar(!openNavbar);
                    navigate("/about");
                  }}
                >
                  <Icon
                    name="bubble_chart"
                    size="2xl"
                    color={pathname.includes("/about") ? "lightBlue" : "white"}
                  />
                  <span
                    className={`pt-1 font-medium text-sm capitalize ${
                      pathname.includes("/about") ? "text-light-blue-500" : ""
                    } `}
                  >
                    About
                  </span>
                </Button>
                <Button
                  color="transparent"
                  className="py-2.5 px-2.5 text-xl flex items-center text-white capitalize"
                  //   size="sm"
                  size="2xl"
                  buttonType="button"
                  ripple="light"
                  onClick={() => {
                    setOpenNavbar(!openNavbar);
                    navigate("/contact-us");
                  }}
                >
                  <Icon
                    name="contact_support"
                    size="2xl"
                    color={pathname.includes("/contact-us") ? "lightBlue" : "white"}
                  />
                  <span
                    className={`pt-1 font-medium text-sm capitalize ${
                      pathname.includes("/contact-us") ? "text-light-blue-500" : ""
                    } `}
                  >
                    Contact
                  </span>
                </Button>
                {/* <Link to="/auth/register" rel="noreferrer"> */}
                <Button
                  color="red"
                  className="bg-white   ml-4 capitalize"
                  ripple="dark"
                  onClick={() => {
                    //console.log("history");

                    setOpenNavbar(!openNavbar);
                    dispatch(initURL("/auth/register"));
                    navigate("/auth/register", { replace: true });
                  }}
                >
                  Join Now
                </Button>

                <Button
                  // color="transparent"

                  color="teal"
                  className="bg-white   ml-4 capitalize"
                  ripple="dark"
                  onClick={() => {
                    //console.log("history");

                    setOpenNavbar(!openNavbar);
                    dispatch(initURL("/auth/login"));
                    navigate("/auth/login", { replace: true });
                  }}
                >
                  Login Now
                </Button>

                {/* </Link> */}
              </div>
            )}
          </Nav>
          <LogoutDialog {...{ open, handleAgree, handleClose }} />
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
