import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
// import Button from "@mui/material/Button";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup

import { Input, Button } from "@material-tailwind/react";
import {
  addUserContact,
  onEditTable,
  selectedDataSetNewValue,
} from "../../appRedux/actions/userContacts";
import { useEffect, useState } from "react";
import { hideDialog } from "../../appRedux/actions/dialog";

const INITIAL_STATE = {
  email: "",
  companyName: "",
  name: "",
  // departmentId: [],
};
export default function Login({ value }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const FormSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    name: Yup.string(),
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    // companyName: Yup.string().required("Company name is required."),
    companyName: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: value || INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      // dispatch(showLoader());
      dispatch(addUserContact({ ...data }));
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values, FormSchema });

  return (
    <>
      <DialogTitle sx={{ paddingLeft: "30px", paddingRight: "30px" }}>Add a new email</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
          Did you miss any email in our list? Please, add it!
        </DialogContentText>
        <FormikProvider value={formik}>
          <Form id="contactForm" autoComplete="off" noValidate className="signin-form w-full  ">
            <div className="  w-full px-4 bg-bb mt-2">
              <Input
                type="email"
                outline={true}
                color="lightBlue"
                placeholder="Email Address"
                // iconName="email"
                {...getFieldProps("email")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">{touched.email && errors.email}</span>
            </div>
            <div className=" w-full px-4 bg-bb">
              <Input
                type="text"
                outline={true}
                color="lightBlue"
                placeholder="Full Name"
                // iconName="account_circle"
                {...getFieldProps("name")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">{touched.name && errors.name}</span>
            </div>{" "}
            <div className="  w-full px-4">
              <Input
                type="text"
                outline={true}
                color="lightBlue"
                placeholder="Company Name"
                // iconName="lock"
                {...getFieldProps("companyName")}
                autoComplete="new-password"
              />
              <span className="text-red-600 px-2 text-xs">
                {touched.companyName && errors.companyName}
              </span>
            </div>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: "50px", paddingRight: "50px" }}>
        <Button
          color="orange"
          className="py-1 capitalize hover:bg-orange-500 hover:text-white mx-2"
          buttonType="filled"
          // onClick={handleClose}
          onClick={() => {
            // setOpenFilter(false);

            dispatch(selectedDataSetNewValue(null));
            dispatch(hideDialog());
            // dispatch(onEditTable({ id: "", status: false, isTrue: false }));
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            // setOpenFilter(false);
            dispatch(selectedDataSetNewValue(null));
            handleSubmit();
          }}
        >
          Add
        </Button>
      </DialogActions>

      {/* <div className="flex  w-max gap-4">
        <Button
          color="teal"
          className="py-1 capitalize hover:bg-teal-500 hover:text-white mx-2"
          buttonType="filled"
          onClick={() => {
            // setOpenFilter(false);
            handleSubmit();
          }}
        >
          Add
        </Button>
        <Button
          color="orange"
          className="py-1 capitalize hover:bg-orange-500 hover:text-white mx-2"
          buttonType="filled"
          onClick={() => {
            // setOpenFilter(false);
            dispatch(onEditTable({ id: "", status: false, isTrue: false }));
          }}
        >
          Cancel
        </Button>
      </div> */}
    </>
  );
}
