import { USER_UPDATE_SUCCESS } from "../actionTypes/user";
import {
  SIGNOUT_AUTH_MEMBER_SUCCESS,
  SIGNIN_MEMBER_SUCCESS,
  SIGNUP_MEMBER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
  MEMBER_CONTACT_US,
  MEMBER_CONTACT_US_SUCCESS,
} from "../actionTypes/auth";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_HIDE_MESSAGE,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
} from "../actionTypes/common";
// import AllDataService from '../services/all.service';
import axiosHttp from "../services/axiosCommon";

export const authLevelChannge = (info) => async (dispatch) => {
  dispatch({
    type: AUTH_LEVEL,
    payload: info,
  });
};
export const memberSignIn = (member) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/user/login", member);
    const { data } = result;

    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("storagePlan", JSON.stringify(data.storagePlan));
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("member", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_MEMBER_SUCCESS,
        payload: data,
      });
      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    //console.log({ err });
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: data && data.memberMessage ? data.memberMessage : "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};

export const memberSignUp = (member) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/user/signup", member);
    const { data } = result;
    //console.log({ data }, "----------------user");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("member", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/auth/verify-code/" + data.data.tempToken,
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNUP_MEMBER_SUCCESS,
        payload: data,
      });

      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};

export const memberSetNewPassword = (member) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/user/forgot/password", member);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("storagePlan", JSON.stringify(data.storagePlan));
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("member", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_MEMBER_SUCCESS,
        payload: data,
      });

      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
export const memberCodeSend = (info) => async (dispatch) => {
  const { type } = info;
  try {
    const result = await axiosHttp.post("/user/code/send", info);
    const { data } = result;
    //console.log({ data }, "----------------user");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("member", JSON.stringify(data.data));
      if (type === "SignUp") {
        dispatch({
          type: INIT_URL,
          payload: "/auth/verify-code/" + data.data.tempToken,
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: "/auth/new-password/" + data.data.tempToken,
        });
      }
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNUP_MEMBER_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// user/code/verify

export const memberCodeVerify = (info) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/user/code/verify", info);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("storagePlan", JSON.stringify(data.storagePlan));
      localStorage.setItem("accessToken", data.authToken);
      localStorage.setItem("member", JSON.stringify(data.data));
      dispatch({
        type: INIT_URL,
        payload: "/transferit",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: SIGNIN_MEMBER_SUCCESS,
        payload: data,
      });

      dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// /user/code/send
export const getMemberTempToken = (queryInfo) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/user/temp/token", {
      params: queryInfo,
    });
    const { data } = result;
    //console.log({ data }, "----------------user");
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      //console.log({ data });
      localStorage.setItem("tempInfo", data);
      // localStorage.setItem("member", JSON.stringify(data.data));
      if (data.data.type !== "5dc189f6a6ee4fcbc4ca93ffe6e8c01d") {
        dispatch({
          type: INIT_URL,
          payload: "/auth/verify-code/" + data.data.tempToken,
        });
      } else {
        dispatch({
          type: INIT_URL,
          payload: "/auth/new-password/" + data.data.tempToken,
        });
      }
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: SIGNUP_MEMBER_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;

    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// /user/temp/token
export const memberSignOut = (info) => async (dispatch) => {
  //console.log("memberSignOut--------");
  dispatch({
    type: INIT_URL,
    payload: "/auth/login",
  });

  dispatch({
    type: SIGNOUT_AUTH_MEMBER_SUCCESS,
  });
  localStorage.clear();
  if (info && info.changeMessage === true) {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: info.payload,
    });
  } else {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: "Logout successfully",
        status: 200,
        error: true,
      },
    });
  }
  const timeout = setTimeout(() => {
    dispatch({
      type: ON_HIDE_MESSAGE,
    });
    dispatch({
      type: ALL_CLEAR_SUCCESS,
    });
  }, 3000);
  return () => {
    clearTimeout(timeout);
  };

  // try {
  //   const result = await axiosHttp.post('/logout', {});
  //   const { data } = result;
  // dispatch({
  //   type: INIT_URL,
  //   payload: "/auth/login"
  // });
  // dispatch({
  //   type: ON_SHOW_MESSAGE,
  //   payload: { memberMessage: 'Logout successfully', status: 200, error: false }
  // });
  // dispatch({
  //   type: SIGNOUT_AUTH_MEMBER_SUCCESS
  // });
  // if (data.error) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  // } else {
  //   localStorage.setItem('accessToken', data.authToken);
  //   localStorage.setItem('member', JSON.stringify(data.data));
  //   dispatch({
  //     type: INIT_URL,
  //     payload: "/auth/login"
  //   });
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  //   dispatch({
  //     type: SIGNIN_MEMBER_SUCCESS,
  //     payload: data
  //   });
  // }

  //   return Promise.resolve(data);
  // } catch (err) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: { memberMessage: 'Request failed', status: 500, error: true }
  //   });
  //   return Promise.reject(err);
  // }
};
export const tokenRemove = () => async (dispatch) => {
  // dispatch({
  //   type: TOKEN_REMOVE,
  // });
};

export const memberContactUs = (member) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: MEMBER_CONTACT_US,
  });

  try {
    const result = await axiosHttp.post("/contactus", member);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: MEMBER_CONTACT_US_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    //console.log({ err });
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        memberMessage: data && data.memberMessage ? data.memberMessage : "Some error has occurred",
        status: 500,
        error: true,
      },
    });
    return Promise.reject(err);
  }
};
// export const memberSignInSuccess = (authMember) => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.create({ title, description });

//     dispatch({
//       type: CREATE_TUTORIAL,
//       payload: res.data
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };

// export const retrieveTutorials = () => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.getAll();

//     dispatch({
//       type: RETRIEVE_TUTORIALS,
//       payload: res.data
//     });
//   } catch (err) {
//     //console.log(err);
//   }
// };

// export const updateTutorial = (id, data) => async (dispatch) => {
//   try {
//     const res = await TutorialDataService.update(id, data);

//     dispatch({
//       type: UPDATE_TUTORIAL,
//       payload: data
//     });

//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
