import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";
import { USER_UPDATE_SUCCESS, USER_ANALYTICS } from "../actionTypes/user";

const INIT_STATE = {
  users: [],
  userList: [],
  isSuccess: false,
  userInfo: {},
  queryInfo: {
    page: 0,
    limit: 10,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [],
  searchName: "",
  searchStatus: false,
  userType: "Active",
  isUpdate: false,
  isUser: true,
  analytics: { totalContact: 0, totalReceived: 0, totalSend: 0 },
};

function userReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        users: [],
        userList: [],
        isSuccess: false,
        userInfo: {},
        queryInfo: {
          page: 0,
          limit: 10,
        },
        totalRecords: 0,
        page: 0,
        columnHandling: [],
        searchName: "",
        searchStatus: false,
        userType: "Active",
        isUpdate: false,
        isUser: true,
        analytics: { totalContact: 0, totalReceived: 0, totalSend: 0 },
      };
    }
    case USER_ANALYTICS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          totalContact: payload.totalContact ? payload.totalContact : state.analytics.totalContact,
          totalReceived: payload.totalReceived
            ? payload.totalReceived
            : state.analytics.totalReceived,
          totalSend: payload.totalSend ? payload.totalSend : state.analytics.totalSend,
        },
      };
    }
    // case USER_UPDATE_SUCCESS: {
    //   //console.log({ payload });
    //   return {
    //     ...state,
    //     userInfo: payload.data,
    //   };
    // }

    default:
      return state;
  }
}

export default userReducer;
