import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup
import { tokenRemove, memberSignIn, memberSignUp } from "../../appRedux/actions/auth";
import {
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  Box,
  Button as ButtonMat,
  Avatar,
} from "@material-ui/core";
import { Input, Button } from "@material-tailwind/react";
import { updateUserContact } from "../../appRedux/actions/userContacts";

const INITIAL_STATE = {
  email: "",
  companyName: "",
  name: "",
  // departmentId: [],
};
export default function Login({ member, updateUserData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const FormSchema = Yup.object().shape({
    // name: Yup.string().required("Name is required"),
    name: Yup.string(),
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    // companyName: Yup.string().required("Company name is required."),
    companyName: Yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: member && member._id ? member : INITIAL_STATE,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      // dispatch(showLoader());
      updateUserData({ ...data });
      // dispatch(updateUser({ ...data }));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values, FormSchema });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        className="signin-form w-full px-8"
        onSubmit={handleSubmit}
      >
        {/* flex display-flex */}
        <div className=" w-full px-4 bg-bb">
          <Input
            type="text"
            outline={true}
            color="lightBlue"
            placeholder="Full Name"
            // iconName="account_circle"
            {...getFieldProps("name")}
            autoComplete="new-password"
            size={"sm"}
          />
          <span className="text-red-600 px-2 text-xs">{touched.name && errors.name}</span>
        </div>{" "}
        <div className="  w-full px-4 bg-bb">
          <Input
            type="email"
            outline={true}
            color="lightBlue"
            placeholder="Email Address"
            // iconName="email"
            {...getFieldProps("email")}
            autoComplete="new-password"
            size={"sm"}
          />
          <span className="text-red-600 px-2 text-xs">{touched.email && errors.email}</span>
        </div>
        <div className="  w-full px-4">
          <Input
            type="text"
            outline={true}
            color="lightBlue"
            placeholder="Company Name"
            // iconName="lock" companyName
            {...getFieldProps("companyName")}
            autoComplete="new-password"
            size={"sm"}
          />
          <span className="text-red-600 px-1 text-xs">
            {touched.companyName && errors.companyName}
          </span>
        </div>
        <div className="  w-full px-4 items-center justify-center pb-2 text-right">
          <Button
            color="orange"
            className="py-1 capitalize     mx-2 display-initial text-center"
            buttonType="outline"
            size={"sm"}
            style={{ display: "initial" }}
          >
            Update
          </Button>
        </div>
        {/* <Button
          color="orange"
          className="py-1 capitalize hover:bg-orange-500 hover:text-white mx-2"
          buttonType="filled"
          onClick={() => {
            onClickEdit({ id: "", status: false, isEdit: false });
          }}
        >
          Cancel
        </Button> */}
      </Form>
    </FormikProvider>
  );
}
