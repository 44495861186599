import SetInnerHTML from "../../common/SetInnerHTML";
import { PrivacyPolicy } from "../../../utils/privacypolicy";

export default function PrivacyPolicyInfo() {
  return (
    <div className="flex flex-wrap items-center mt-20 mb-20 px-20 justify-center">
      <SetInnerHTML innerHTML={PrivacyPolicy} />
    </div>
  );
}
