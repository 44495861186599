import H3 from "@material-tailwind/react/Heading3";
import Paragraph from "@material-tailwind/react/Paragraph";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Button from "@material-tailwind/react/Button";

import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { memberContactUs } from "../../appRedux/actions/auth";
import { useEffect, useState } from "react";
const INITIAL_STATE = {
  email: "",
  message: "",
  name: "",
  // departmentId: [],
};
export default function FormView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contactStatus } = useSelector((state) => ({
    contactStatus: state.member.contactStatus,
  }));
  const [initValue, setInitValue] = useState({
    email: "",
    message: "",
    name: "",
    subject: "",
  });
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email must be a valid email address").required("Email is required"),
    message: Yup.string().required("Message is required."),
    subject: Yup.string().required("Subject is required."),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      dispatch(memberContactUs({ ...data }));
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps, handleChange, handleReset } =
    formik;

  useEffect(() => {
    if (!contactStatus) {
      handleReset();
    }
  }, [contactStatus]);

  //console.log({ errors, touched, values, formik });
  return (
    <div className="flex flex-wrap justify-center mt-24">
      <div className="w-full lg:w-8/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div className="flex-auto p-5 lg:p-10">
            <div className="w-full text-center">
              <H3 color="gray">Want to work with us?</H3>
              <Paragraph color="blueGray">
                Complete this form and we will get back to you in 24 hours.
              </Paragraph>
            </div>

            <FormikProvider value={formik}>
              <Form
                id="contactForm"
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                className="signin-form"
              >
                <div className="flex gap-8 mt-10 ">
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder="Full Name"
                      color="lightBlue"
                      {...getFieldProps("name")}
                    />

                    <span className="text-red-600 px-2 text-xs">{touched.name && errors.name}</span>
                  </div>
                  <div className="w-full">
                    <Input
                      type="email"
                      placeholder="Email Address"
                      color="lightBlue"
                      {...getFieldProps("email")}
                    />
                    <span className="text-red-600 px-2 text-xs">
                      {touched.email && errors.email}
                    </span>
                  </div>
                </div>

                <div className="w-full mb-4">
                  <Input
                    type="text"
                    placeholder="Subject"
                    color="lightBlue"
                    {...getFieldProps("subject")}
                  />
                  <span className="text-red-600 px-2 text-xs">
                    {touched.subject && errors.subject}
                  </span>
                </div>
                <Textarea color="lightBlue" placeholder="Message" {...getFieldProps("message")} />

                <span className="text-red-600 px-2 text-xs">
                  {touched.message && errors.message}
                </span>
                <div className="flex justify-center mt-10">
                  <Button color="lightBlue" ripple="light">
                    Send Message
                  </Button>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
