import { useState, useEffect } from "react";
import AWS from "aws-sdk";
import async from "async";
import { Wave } from "react-animated-text";
import Button from "@material-tailwind/react/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { setDefaultProgressStatus, setProgress } from "../../appRedux/actions/common";
import {
  deleteDataTransfers,
  deleteTransfers,
  sendTransferEmail,
} from "../../appRedux/actions/transfer";
import {
  CONTABO_STORAGE_BUCKET_URL,
  ACCESS_KEY_HERE,
  SECRET_ACCESS_KEY_HERE,
  BUCKET_NAME_HERE,
  HTTP_OPTIONS,
} from "../../utils/config";
const S3_BUCKET = BUCKET_NAME_HERE;
AWS.config.update({
  endpoint: CONTABO_STORAGE_BUCKET_URL,
  accessKeyId: ACCESS_KEY_HERE,
  secretAccessKey: SECRET_ACCESS_KEY_HERE,
  s3BucketEndpoint: true,
  signatureVersion: "v4",
  maxRetries: 2,
  httpOptions: HTTP_OPTIONS,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  // region: REGION,
});

var request;
const AbortRequest = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [transferCancel, setTransferCancel] = useState(false);

  const { tProgress, loaded, member, fileListInfo, setBucketData, transferSuccessInfo } =
    useSelector((state) => ({
      loaded: state.common.progressEvent.loaded,
      tProgress: state.common.progressEvent.progress,

      totalSize: state.common.progressEvent.total,
      UUID: state.common.UUID,
      accessToken: state.auth.accessToken,

      transferCancel: state.transfer.transferCancel,
      member: state.member.member,
      fileListInfo: state.dropZone.fileListInfo,
      setBucketData: state.transfer.setBucketData,
      transferSuccessInfo: state.transfer.transferSuccessInfo,
    }));

  var params = {};
  const cbFunction = (info, totalSize, member, cb) => {
    params = {
      ACL: "public-read",
      Body: info.files,
      Bucket: S3_BUCKET + "/" + member._id,
      Key: info.alteredName,
    };

    request = myBucket.upload(params);
    request.on("httpUploadProgress", (progress, response) => {
      const { total, loaded, type, key } = progress;

      dispatch(
        setProgress({
          totalSize: totalSize,
          curTotal: total,
          loaded: loaded,
          type,
          key,
          uploadProgress: progress,
        })
      );
    });
    request.send((err, data) => {
      cb(err, { ...data, transferCancel });
    });
  };

  useEffect(() => {
    if (setBucketData && transferSuccessInfo && transferSuccessInfo._id) {
      setTransferCancel(false);
      dispatch(setDefaultProgressStatus());

      const { totalSize, _id } = transferSuccessInfo;
      async.mapSeries(
        fileListInfo,
        function (info, next) {
          cbFunction(info, totalSize, member, function (err, result) {
            if (err) {
              next(err);
            } else {
              next(null, result);
            }
          });
        },
        function (err, results) {
          if (err) {
            dispatch(deleteDataTransfers({ transferId: _id }));
          } else {
            dispatch(sendTransferEmail({ transferId: _id }));
          }
        }
      );
    }
  }, [setBucketData]);

  function handleClick() {
    setTransferCancel(true);
    request.abort();
    request.abort.bind(request);
  }

  useEffect(() => {
    if (pathname !== "/transferit") {
      setTransferCancel(true);
      request.abort();
      request.abort.bind(request);
    }
  }, [pathname]);
  return (
    <>
      {tProgress !== 0 && tProgress === 100 ? (
        <p className="text-center m-3">
          <Wave text="Processing..." effect="stretch" effectChange={3.0} />
        </p>
      ) : (
        ""
      )}

      {loaded > 0 && tProgress !== 100 ? (
        <Button
          className="m-auto"
          color="lightBlue"
          buttonType="submit"
          size="sm"
          ripple="dark"
          onClick={() => {
            handleClick();
          }}
        >
          <span className="font-medium text-xl capitalize">Cencel</span>
        </Button>
      ) : (
        ""
      )}
    </>
    // <div className="App">
    //   <h1>axios cancel</h1>
    //   <button onClick={handleClick}>Cancel</button>
    //   <button
    //     onClick={() => {
    //       console.log("refetching");
    //       setLoading(true);
    //       setCanceled("");
    //       setData([]);
    //       setRefetch(true);
    //     }}
    //   >
    //     Refetch
    //   </button>
    //   {loading && <h3>Loading...</h3>}
    //   {canceled && <h3>Canceled!</h3>}
    //   {Object.keys(data) != 0 && <p>{JSON.stringify(data, null, 2)}</p>}
    // </div>
  );
};

export default AbortRequest;
