import React from "react";

import { experimentalStyled as styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { bytesToSize, sizeToBytes } from "../../utils/fileConstant";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#03a9f4" : "#308fe8",
  },
}));
const BorderLinearProgressDenger = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#f44336" : "#308fe8",
  },
}));

const Storage = () => {
  const { totalSize, storagePlan, useStorage, accessData } = useSelector((state) => ({
    totalSize: state.dropZone.totalSize,
    useStorage: state.auth.storage ? state.auth.storage.useStorage : 0,
    storagePlan: state.auth.storage ? state.auth.storage.storagePlan : {},
    accessData:
      state.auth.storage && state.auth.storage.storagePlan
        ? state.auth.storage.storagePlan.accessData
        : { limit: 0, limitIn: "" },
  }));
  //   const { accessData } = storagePlan;
  const storage = accessData ? sizeToBytes(accessData.limit, accessData.limitIn) : 0;
  //console.log({ storagePlan, storage, useStorage, totalSize });
  const value =
    storage >= useStorage + totalSize
      ? Math.round((100 / storage) * (useStorage + totalSize))
      : Math.round((100 / storage) * storage);
  //console.log({ value, storagePlan });
  return (
    <Box sx={{ flexGrow: 1 }}>
      {storage >= useStorage + totalSize ? (
        <BorderLinearProgress variant="determinate" value={value} maxValue={storage} />
      ) : (
        <BorderLinearProgressDenger variant="determinate" value={value} maxValue={storage} />
      )}
      {`${bytesToSize(useStorage + totalSize)} of ${accessData.limit} ${accessData.limitIn} used`}
    </Box>
  );
};

export default Storage;
