import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
// import { Icon, Button } from "@material-tailwind/react";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AlertDialogSlide({ children }) {
  //console.log({ children });
  const { props } = children;
  const { open, title, description, footer, handleClose, handleAgree } = props;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          {children}
          {/* <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleClose}
            color={"gray"}
            className="py-1 my-1 capitalize mx-2"
            buttonType="filled"
          >
            Disagree
          </Button>
          <Button
            onClick={handleAgree}
            color={"red"}
            className="py-1 my-1 capitalize mx-2"
            buttonType="filled"
          >
            Agree
          </Button> */}

          <Button onClick={() => handleClose()} color="inherit">
            Disagree
          </Button>
          <Button onClick={() => handleAgree()} color="error">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
