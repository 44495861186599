import {
  USER_CONTACT_CREATE_SUCCESS,
  USER_CONTACT_LIST,
  USER_CONTACT_LIST_SUCCESS,
  USER_CONTACT_INFO_SUCCESS,
  USER_CONTACT_UPDATE_SUCCESS,
  USER_CONTACT_DELETE_SUCCESS,
  USER_CONTACT_FILTER_HANDLING,
  USER_CONTACT_SEARCH_NAME,
  USER_CONTACT_SELECTED,
  USER_CONTACT_SELECTED_TUCHED,
  USER_CONTACT_EDIT_TABLE,
  USER_CONTACT_SELECTED_CONTACT,
  USER_CONTACT_SELECTED_SET_NEW_VALUE,
} from "../actionTypes/userContacts";
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from "../actionTypes/common";
import { memberSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";
import { DIALOG_HIDE } from "../actionTypes/dialog";
// getUserContactList;

export const selectedDataSetNewValue = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: USER_CONTACT_SELECTED_SET_NEW_VALUE,
    payload: dataInfo,
  });
};
export const selectedDataUserContacts = (dataInfo) => async (dispatch) => {
  //console.log({ dataInfo });
  dispatch({
    type: USER_CONTACT_SELECTED,
    payload: dataInfo,
  });
};

export const getAllUserContacts = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/user/contact/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: USER_CONTACT_LIST,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getUserContact = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  // searchText
  const { page, searchText } = queryInfo;
  try {
    const result = await axiosHttp.get("/user/contact", {
      params: queryInfo,
    });
    const { data } = result;
    if (data.error && data.status !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/user/contact/list",
      // });
      if ((!searchText || searchText === "") && page === 0) {
        dispatch({
          type: ON_SHOW_MESSAGE,
          payload: data,
        });
      } else {
        dispatch({ type: ON_HIDE_LOADER });
      }
      dispatch({
        type: USER_CONTACT_LIST_SUCCESS,
        payload: { ...data, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getUserContactInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/user/contact", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: USER_CONTACT_INFO_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const addUserContact = (infoData) => async (dispatch) => {
  //console.log({ infoData }, "addUserContact-------------+++++++++++===");
  const { queryInfo } = infoData;
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/user/contact", {
      ...infoData,
      queryInfo: undefined,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/user/contact/list",
      // });
      if (!queryInfo) {
        dispatch({ type: DIALOG_HIDE });
        dispatch({ type: USER_CONTACT_SELECTED_CONTACT, payload: data });
      }
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      if (queryInfo) {
        dispatch(getUserContact(queryInfo));
      }
      dispatch({
        type: USER_CONTACT_CREATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateUserContact = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.put("/user/contact", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/user/contact/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: USER_CONTACT_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteUserContact = (infoData) => async (dispatch) => {
  const { queryInfo } = infoData;
  //console.log({ queryInfo });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.delete("/user/contact", {
      params: {
        ...infoData,
        queryInfo: undefined,
      },
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/user/contact/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });

      if (queryInfo) {
        dispatch(getUserContact(queryInfo));
      }
      dispatch({
        type: USER_CONTACT_DELETE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: USER_CONTACT_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: USER_CONTACT_FILTER_HANDLING,
    payload: info,
  });
};

export const onEditTable = (info) => async (dispatch) => {
  dispatch({
    type: USER_CONTACT_EDIT_TABLE,
    payload: info,
  });
};
