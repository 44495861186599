module.exports = {
  apiPrefix:
    process.env.REACT_APP_TEST === "production"
      ? "https://transferitnow.com"
      : process.env.REACT_APP_TEST === "staging"
      ? "http://staging.transferitnow.com"
      : process.env.REACT_APP_TEST === "development"
      ? "http://localhost:9010"
      : "http://localhost:9010",
  socketURL:
    process.env.REACT_APP_TEST === "production"
      ? "https://transferitnow.com"
      : process.env.REACT_APP_TEST === "staging"
      ? "http://staging.transferitnow.com"
      : process.env.REACT_APP_TEST === "development"
      ? "http://localhost:9010"
      : "http://localhost:9010",
  reportDownloadUrl:
    process.env.REACT_APP_TEST === "production"
      ? "https://transferitnow.com/reports"
      : process.env.REACT_APP_TEST === "staging"
      ? "http://staging.transferitnow.com/reports"
      : process.env.REACT_APP_TEST === "development"
      ? "http://localhost:9010/reports"
      : "http://localhost:9010/reports",
  uploadMediaUrl:
    process.env.REACT_APP_TEST === "production"
      ? "https://transferitnow.com/media"
      : process.env.REACT_APP_TEST === "staging"
      ? "http://staging.transferitnow.com/media"
      : process.env.REACT_APP_TEST === "development"
      ? "http://localhost:9010/media"
      : "http://localhost:9010/media",
  apiURL:
    process.env.REACT_APP_TEST === "production"
      ? "https://transferitnow.com/api"
      : process.env.REACT_APP_TEST === "staging"
      ? "http://staging.transferitnow.com/api"
      : process.env.REACT_APP_TEST === "development"
      ? "http://localhost:9010/api"
      : "http://localhost:9010/api",
  PROD_SITE_URL: "https://transferitnow.com",
  DEV_SITE_URL: "http://development.transferitnow.com",
  STAGING_SITE_URL: "http://staging.transferitnow.com",

  CONTABO_STORAGE_URL: "https://eu2.contabostorage.com/",
  CONTABO_STORAGE_BUCKET_URL:
    process.env.REACT_APP_TEST === "production"
      ? "https://eu2.contabostorage.com/transferitnow.com"
      : process.env.REACT_APP_TEST === "staging"
      ? "https://eu2.contabostorage.com/staging.transferitnow.com"
      : process.env.REACT_APP_TEST === "development"
      ? "https://eu2.contabostorage.com/development.transferitnow.com"
      : "https://eu2.contabostorage.com/development.transferitnow.com",
  ACCESS_KEY_HERE: "40bccb0f9a4641d3874124f478a0264e",
  SECRET_ACCESS_KEY_HERE: "9269beaa64698b99079d77a2215e7d7d",
  BUCKET_NAME_HERE:
    process.env.REACT_APP_TEST === "production"
      ? "transferitnow.com"
      : process.env.REACT_APP_TEST === "staging"
      ? "staging.transferitnow.com"
      : process.env.REACT_APP_TEST === "development"
      ? "development.transferitnow.com"
      : "development.transferitnow.com",
  PROD_NAME_HERE: "transferitnow.com",
  DEV_NAME_HERE: "development.transferitnow.com",
  STAGING_NAME_HERE: "staging.transferitnow.com",
  HTTP_OPTIONS: {
    timeout: 20 * 60 * 60 * 1000,
    connectTimeout: 4 * 60 * 60 * 1000,
  },
  // apiPrefix: "http://localhost:9010", //"http://staging.transferitnow.com", //
  // socketURL: "http://localhost:9010", //"http://staging.transferitnow.com", //,
  // reportDownloadUrl: "http://localhost:9010/reports", //"http://staging.transferitnow.com/reports", //
  // uploadMediaUrl: "http://localhost:9010/media", //"http://staging.transferitnow.com/media", //,
  // apiURL: "http://localhost:9010/api", //"http://staging.transferitnow.com/api", //
};
