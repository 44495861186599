import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Collapse,
  Box,
  Button as ButtonMat,
} from "@material-ui/core";
import Label from "./Label";
import SearchNotFound from "../SearchNotFound";
import ResultNotFound from "../ResultNotFound";
// import { ListHead, MoreMenu, CustomizedDialogs, ListViewCourse } from ".";
import { ListHead, MoreMenu } from ".";
import { Icon, Button } from "@material-tailwind/react";
import { bytesToSize } from "../../utils/fileConstant";
import { mDateTime, mTime, mDate, fToNow, diffYMDHMS, fExpiredAt } from "../../utils/formatTime";
//

// ----------------------------------------------------------------------

function Row(props) {
  const navigate = useNavigate();
  const { row, columnHandling } = props;
  const { _id, subject, contactsInfo, status, totalSize, expireAt } = row;
  // setInterval(() => {}, 1000);
  const diffTime = fExpiredAt(expireAt);
  return (
    <React.Fragment>
      <TableRow hover key={_id}>
        {columnHandling.map((col, index) => {
          const { id, key, childKey, align } = col;

          // alt={row[key][childKey]}
          if (key === "senderInfo" && row[key]) {
            return (
              <TableCell component="th" scope="row" spacing={2} sx={{ padding: "6px 16px" }}>
                <Stack direction="row" alignItems="left" spacing={2}>
                  {/* <Avatar alt={subject} src={subject} /> */}
                  <Typography variant="subtitle2" noWrap>
                    {/* <Link to={`/learner/${_id}/info`}> */}
                    {row[key][childKey]}
                    {/* </Link> */}
                  </Typography>
                </Stack>
              </TableCell>
            );
          }
          if (key === "subject") {
            return (
              <TableCell component="th" scope="row" spacing={2} sx={{ padding: "6px 16px" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* <Avatar alt={subject} src={subject} /> */}
                  <Typography variant="subtitle2" noWrap>
                    {subject || "..."}
                  </Typography>
                </Stack>
              </TableCell>
            );
          }
          if (key === "totalSize") {
            return (
              <TableCell align={align} sx={{ padding: "6px 16px" }}>
                {bytesToSize(totalSize)}
              </TableCell>
            );
          }
          if (key === "_id") {
            return (
              <TableCell align={align} id="buttonTable2" sx={{ padding: "6px 16px" }}>
                <Button
                  color={`${
                    diffTime.status === "error" || status === "Banned" ? "gray" : "lightBlue"
                  }`}
                  className="py-1 capitalize   mx-2 "
                  buttonType="filled"
                  onClick={() => {
                    if (diffTime.status !== "error" && status !== "Banned") {
                      navigate("/history/" + _id + "/received");
                    }
                  }}
                  disabled={`${status === "Banned" ? true : false}`}
                >
                  View
                </Button>
              </TableCell>
            );
          }
          if (key === "active") {
            return (
              <TableCell align="center" sx={{ padding: "6px 16px" }}>
                <Label variant="ghost" color={(row[key] === false && "error") || "success"}>
                  {row[key] === false ? "Banned" : "Active"}
                </Label>
              </TableCell>
            );
          }
          if (key === "createdAt") {
            return (
              <TableCell align={align} sx={{ padding: "6px 16px" }}>
                <p className="text-sm ">{mTime(row[key])}</p>
                <span className="text-[12px] font-bold">{mDate(row[key])}</span>
                {/* <p className={`text-sm `}>{diffYMDHMS(row[key])}</p> */}
              </TableCell>
            );
          }
          if (key === "expireAt" && status !== "Banned") {
            return (
              <TableCell align={align} sx={{ padding: "6px 16px" }}>
                <Label color={diffTime.status}>{diffTime.msg}</Label>
              </TableCell>
            );
          }

          if (key === "expireAt" && status === "Banned") {
            return (
              <TableCell key={key} align={align} sx={{ padding: "6px 16px" }}>
                <Label color="default" sx={{ color: "#3d0202", backgroundColor: "#625e5e54" }}>
                  Banned
                </Label>
              </TableCell>
            );
          }
          return key === "action" ? (
            <TableCell align={align} sx={{ padding: "6px 16px" }}>
              <MoreMenu learnerId={_id} index={index} status={status} />
            </TableCell>
          ) : (
            <TableCell align={align} sx={{ padding: "6px 16px" }}>
              {row[key]}
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
}
export default function ListView() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [courseInfo, setCourseInfo] = useState([]);
  // const [columnHandling, setColumnHandling] = useState([]);

  const { searchName, transferList, columnHandling, searchStatus, limit } = useSelector(
    (state) => ({
      transferList: state.transfer.transferList,
      searchStatus: state.transfer.searchStatus,
      // searchName: state.transfer.searchName,
      searchName: state.transfer.queryInfo.searchText,
      page: state.transfer.queryInfo.page,
      limit: state.transfer.queryInfo.limit,
      columnHandling: state.transfer.columnHandlingReceived.filter(
        (item) => item.visibility || !item.isFilter
      ),
    })
  );

  useEffect(() => {
    // setColumnHandling(columnHandling2);
  }, []);
  const emptyRows = 0;

  // const emptyRows = Math.max(0, limit - transferList.length) || 0;
  const props = {
    title: "All Course",
    courseInfo,
    visible,
    footer: null,
    onCancel: () => setVisible(false),
  };
  return (
    <>
      <TableContainer sx={{ minWidth: 280 }}>
        <Table>
          <ListHead headLabel={columnHandling} checkbox={false} isSortLabel={false} />
          <TableBody>
            {transferList &&
              transferList.length > 0 &&
              transferList.map((row) => (
                <Row key={row.name} row={row} columnHandling={columnHandling} />
              ))}
          </TableBody>
          <TableBody>
            {emptyRows > 0 && transferList.length !== 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          {transferList.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                  {searchName && searchName !== "" ? (
                    <SearchNotFound searchQuery={searchName} />
                  ) : (
                    <ResultNotFound resultsQuery={` History`} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* <CustomizedDialogs>
        <ListViewCourse alt="example" {...props} />
      </CustomizedDialogs> */}
    </>
  );
}
