import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

export default function SimpleBackdrop() {
  const [open, setOpen] = React.useState(false);

  const { loader } = useSelector((state) => ({
    loader: state.common.loader,
  }));

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
