import { useSelector } from "react-redux";
import Header from "../components/common/Header";
import Form from "../components/contactUs/Form";
import Form2 from "../components/contactUs/Form2";

export default function ContactUs() {
  const { accessToken, routeLevel } = useSelector((state) => ({
    routeLevel: state.auth.routeLevel,
    accessToken: state.auth.accessToken,
  }));
  //

  return (
    <main>
      <Header pageName={"Contact Us"} />
      <section className="relative py-16 bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-6">{accessToken !== null ? <Form /> : <Form2 />}</div>
          </div>
        </div>
      </section>
    </main>
  );
}
