import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import useWindowDimensions from "../../components/common/WindowDimensions";
import { useSelector } from "react-redux";
import { bytesToSize } from "../../utils/fileConstant";
import AbortRequest from "./abortRequest";
// import { AWSProvider } from "../../appRedux/services/awsContext";
// import CancleRequest from "./cancleRequest";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress size="200px" variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          sx={{ fontSize: "40px", color: "#ffffff" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic({ open }) {
  const { progress, totalSize, loadedFileSize } = useSelector((state) => ({
    progress: state.common.progressEvent.progress,
    totalSize: state.common.progressEvent.total,
    loadedFileSize: state.common.progressEvent.loaded,
  }));

  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <div>
        <CircularProgressWithLabel value={progress > 100 ? 100 : progress} />
        <p className="text-center m-3">
          {bytesToSize(loadedFileSize)} of {bytesToSize(totalSize)}
        </p>
        <AbortRequest />
      </div>
    </Backdrop>
  );
}
