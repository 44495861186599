export const ASSOCIATE_CREATE = 'ASSOCIATE_CREATE';
export const ASSOCIATE_CREATE_SUCCESS = 'ASSOCIATE_CREATE_SUCCESS';
export const ASSOCIATE_CREATE_ERROR = 'ASSOCIATE_CREATE_ERROR';
export const ASSOCIATE_LIST = 'ASSOCIATE_LIST';
export const ASSOCIATE_LIST_SUCCESS = 'ASSOCIATE_LIST_SUCCESS';
export const ASSOCIATE_LIST_ERROR = 'ASSOCIATE_LIST_ERROR';
export const ASSOCIATE_INFO = 'ASSOCIATE_INFO';
export const ASSOCIATE_INFO_SUCCESS = 'ASSOCIATE_INFO_SUCCESS';
export const ASSOCIATE_INFO_ERROR = 'ASSOCIATE_INFO_ERROR';
export const ASSOCIATE_UPDATE_SUCCESS = 'ASSOCIATE_UPDATE_SUCCESS';
export const ASSOCIATE_UPDATE_ERROR = 'ASSOCIATE_UPDATE_ERROR';
export const ASSOCIATE_DELETE_SUCCESS = 'ASSOCIATE_DELETE_SUCCESS';
export const ASSOCIATE_FILTER_HANDLING = 'ASSOCIATE_FILTER_HANDLING';
export const ASSOCIATE_SEARCH_NAME = 'ASSOCIATE_SEARCH_NAME';
