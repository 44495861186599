import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// npm i formik  yup
import {
  getMemberTempToken,
  tokenRemove,
  memberCodeSend,
  memberCodeVerify,
  memberSignIn,
} from "../../appRedux/actions/auth";
import { showLoader } from "../../appRedux/actions/common";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Input from "@material-tailwind/react/Input";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";
import OtpInput from "react-otp-input";
// import OutlinedInput from '@mui/material/OutlinedInput';
const INITIAL_STATE = {
  code: "",
};
export default function NewPassword() {
  const dispatch = useDispatch();
  const { tempId } = useParams();

  const [code, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { tempToken, authLevel, tempInfo, member } = useSelector((state) => ({
    loader: state.common.loader,
    authLevel: state.auth.authLevel,
    tempToken: state.auth.tempToken,
    url: state.common.initURL,
    accessToken: state.auth.accessToken,
    member: state.auth.member,
    tempInfo: state.auth.tempInfo,
  }));
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(() => {
    // if (tempToken) dispatch(tokenRemove());
    dispatch(getMemberTempToken({ tempToken: tempId }));
  }, []);
  const FormSchema = Yup.object().shape({
    code: Yup.string().min(6).required("Code is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...INITIAL_STATE, tempToken: tempId },
    validationSchema: FormSchema,
    onSubmit: (data) => {
      //console.log({ data }, "update");
      dispatch(showLoader());
      dispatch(memberCodeVerify({ ...data }));
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
  } = formik;
  // const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  //console.log({ errors, touched, values, matches });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Card>
      <CardHeader color="lightBlue" className="h-auto" size="sm">
        <H5 color="white" style={{ marginBottom: 0 }}>
          Verify Code!
        </H5>
      </CardHeader>
      <div className="mb-2 px-4 bg-bb text-center">
        <span className="  text-xs">
          We've sent a 6-digit confirmation email to{" "}
          <span className=" text-light-blue-500">{tempInfo.email}</span>. Please enter the code in
          below box to verify your email.{" "}
        </span>
      </div>
      <CardBody>
        {/* and We will
        email you a link to reset your password. */}
        <FormikProvider value={formik}>
          <Form
            id="contactForm"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            className="signin-form"
          >
            <div className="mb-6 px-4 bg-bb"></div>{" "}
            <div className={matches ? "mb-2 px-4 bg-bb" : "mb-2 px-4 bg-bb"}>
              <div className={matches ? " px-3  " : ""}>
                <OtpInput
                  value={values.code}
                  onChange={(code) => {
                    let event = {
                      target: {
                        name: "code",
                        value: code,
                      },
                    };
                    handleChange(event);
                  }}
                  onBlur={() => {
                    handleBlur({ target: { name: "code" } });
                  }}
                  numInputs={6}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={
                    matches
                      ? {
                          border: "1px solid #CFD3DB",
                          borderRadius: "8px",
                          width: "48px",
                          height: "48px",
                          fontFamily: "inherit",
                          fontSize: "100%",
                          color: "rgba(66, 66, 66,1)",
                          fontWeight: "500",
                          caretColor: "blue",
                        }
                      : {
                          border: "2px solid #CFD3DB",
                          borderRadius: "8px",
                          width: "30px",
                          height: "38px",
                          fontFamily: "inherit",
                          fontSize: "100%",
                          color: "rgba(66, 66, 66,1)",
                          fontWeight: "500",
                          caretColor: "blue",
                        }
                  }
                  focusStyle={{
                    border: "2px solid rgba(2, 136, 209,1)",
                    outline: "none",
                  }}
                />
              </div>
              <span className="text-red-600 px-2  text-xs">{touched.code && errors.code}</span>
            </div>
            <div className="flex justify-center bg-bb py-1.5">
              <Button color="lightBlue" buttonType="submit" size="lg" ripple="dark">
                Verify
              </Button>
            </div>{" "}
            <div className=" px-4 bg-bb text-center">
              <span className="  text-xs">
                Don’t have a code?{" "}
                <span
                  className="text-light-blue-500"
                  color="lightBlue"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(memberCodeSend({ tempToken: tempId, type: tempInfo.type }));
                  }}
                >
                  Resend Code
                </span>
              </span>
            </div>
          </Form>
        </FormikProvider>
      </CardBody>
    </Card>
  );
}
