import { combineReducers } from "redux";
import auth from "./auth";
import common from "./common";
import associate from "./associate";
import dialog from "./dialog";
import dropZone from "./dropZone";
import assigned from "./assigned";
import user from "./user";
import userContacts from "./userContacts";
import transfer from "./transfer";
import member from "./member";

export default combineReducers({
  assigned,
  associate,
  auth,
  common,
  user,
  userContacts,
  dialog,
  dropZone,
  transfer,
  member,
});
