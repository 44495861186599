import H3 from "@material-tailwind/react/Heading4";
export default function Header({ pageName }) {
  return (
    <section className="relative block h-[380px]">
      <div className="bg-profile-background bg-cover bg-center absolute top-0 w-full h-full" />
      {pageName ? (
        <section className="relative text-center   py-[6rem] ">
          <H3 color="white">{pageName}</H3>
        </section>
      ) : (
        ""
      )}
    </section>
  );
}
