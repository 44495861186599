import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
import { TextField } from "@material-ui/core";
import { Label } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";

import { selectedDataUserContacts } from "../../appRedux/actions/userContacts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    // minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function MessageValue() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => ({
    selectedData: state.userContacts.selectedData,
  }));

  const { values, touched, errors } = selectedData;
  return (
    <>
      <Paper className={`${classes.root} `}>
        <Label color={"black"}>Message</Label>
        <div className="mb-1 mt-0 px-4 pb-4 bg-bb w-full" id="removed">
          <TextField
            id="standard-multiline-static"
            multiline
            type="text"
            size="small"
            fullWidth
            value={values.message}
            minRows={5}
            maxRows={7}
            variant="standard"
            onChange={(e) => {
              dispatch(
                selectedDataUserContacts({
                  values: { ...values, message: e.target.value },
                  touched: { ...touched, message: true },
                  errors: {
                    ...errors,
                    message: e.target.value !== "" ? "" : "Message is required. ",
                  },
                })
              );
            }}
            onBlur={() => {
              dispatch(
                selectedDataUserContacts({
                  errors: {
                    ...errors,
                    message: values.message !== "" ? "" : "Message is required. ",
                  },
                  touched: { ...touched, message: true },
                })
              );
            }}
          />
        </div>
        {/* <p className="text-red-500 px-4">
          {touched.message && errors.message && errors.message !== ""
            ? errors.message
            : ""}
        </p> */}
      </Paper>
    </>
  );
}
