export const TRANSFER_CREATE = "TRANSFER_CREATE";
export const TRANSFER_CREATE_SUCCESS = "TRANSFER_CREATE_SUCCESS";
export const TRANSFER_CREATE_ERROR = "TRANSFER_CREATE_ERROR";
export const TRANSFER_LIST = "TRANSFER_LIST";
export const TRANSFER_LIST_SUCCESS = "TRANSFER_LIST_SUCCESS";
export const TRANSFER_LIST_ERROR = "TRANSFER_LIST_ERROR";
export const TRANSFER_INFO = "TRANSFER_INFO";
export const TRANSFER_INFO_SUCCESS = "TRANSFER_INFO_SUCCESS";
export const TRANSFER_INFO_ERROR = "TRANSFER_INFO_ERROR";
export const TRANSFER_UPDATE_SUCCESS = "TRANSFER_UPDATE_SUCCESS";
export const TRANSFER_UPDATE_ERROR = "TRANSFER_UPDATE_ERROR";
export const TRANSFER_DELETE_SUCCESS = "TRANSFER_DELETE_SUCCESS";
export const TRANSFER_FILTER_HANDLING = "TRANSFER_FILTER_HANDLING";
export const TRANSFER_SEARCH_NAME = "TRANSFER_SEARCH_NAME";
export const TRANSFER_SELECTED = "TRANSFER_SELECTED";
export const TRANSFER_SELECTED_TUCHED = "TRANSFER_SELECTED_TUCHED";
export const TRANSFER_SELECTED_RESET = "TRANSFER_SELECTED_RESET";
export const TRANSFER_SEARCH_NAME_CLEAR = "TRANSFER_SEARCH_NAME_CLEAR";
export const TRANSFER_RECEIVED = "TRANSFER_RECEIVED";
export const TRANSFER_BUCKET_STATUS = "TRANSFER_BUCKET_STATUS";
export const TRANSFER_PROGRESS = "TRANSFER_PROGRESS";
export const TRANSFER_DETAILS_INFO_SUCCESS = "TRANSFER_DETAILS_INFO_SUCCESS";
export const TRANSFER_CENCEL = "TRANSFER_CENCEL";
