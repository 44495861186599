import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

ResultsNotFound.propTypes = {
  resultsQuery: PropTypes.string
};

export default function ResultsNotFound({ resultsQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography>
      <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{`${resultsQuery} List`}&quot; </strong>. Add information this type of{' '}
        <strong>
          &quot;
          {resultsQuery}&quot;
        </strong>
        .
      </Typography>
    </Paper>
  );
}
