import {
  format,
  formatDistance,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatDuration,
} from "date-fns";
import moment from "moment";
// ----------------------------------------------------------------------

export function mDate(date) {
  return moment(new Date(date)).format("Do MMM yyyy");
}
export function mTime(date) {
  return moment(new Date(date)).format("h:mm:ss A");
}
export function mDateTime(date) {
  return moment(new Date(date)).format("h:mm:ss A,Do MMM yyyy");
}
export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  // return formatDistanceToNow(new Date(date), {
  //   addSuffix: true,
  // });
  return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
}

export function fExpiredAt(date) {
  var date2 = moment(new Date());
  var date1 = moment(new Date(date));

  let days = date1.diff(date2, "days");
  date2.add(days, "days");

  let hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");

  let minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");

  let seconds = date1.diff(date2, "seconds");
  var msg = "";
  var status = "green";
  if (hours > 2) {
    msg =
      hours +
      (hours > 1 ? " hours " : " hour ") +
      minutes +
      (minutes > 1 ? " minutes" : " minute");
    status = "success";
  } else if (hours > 0) {
    msg =
      hours +
      (hours > 1 ? " hours " : " hour ") +
      minutes +
      (minutes > 1 ? " minutes" : " minute");
    status = "warning";
  } else if (minutes > 0) {
    msg = minutes + (minutes > 1 ? " minutes" : " minute");
    status = "warning";
  } else if (seconds > 0) {
    msg = "Expire in " + seconds + (seconds > 1 ? " seconds" : " second");
    status = "warning";
  } else {
    msg = "Expired";
    status = "error";
  }

  return { msg, status };
  // return { days, hours, minutes, seconds };
}

export function diffYMDHMS(date) {
  var date2 = moment(new Date());
  var date1 = moment(new Date(date));
  let years = date1.diff(date2, "year");
  date2.add(years, "years");

  let months = date1.diff(date2, "months");
  date2.add(months, "months");

  let days = date1.diff(date2, "days");
  date2.add(days, "days");

  let hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");

  let minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");

  let seconds = date1.diff(date2, "seconds");

  // //console.log(
  //   years +
  //     " years " +
  //     months +
  //     " months " +
  //     days +
  //     " days " +
  //     hours +
  //     "hours " +
  //     minutes +
  //     " minutes " +
  //     seconds +
  //     " seconds"
  // );

  return { days, hours, minutes, seconds };
}
