import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Icon, Button } from "@material-tailwind/react";

import { deleteTransfers } from "../../appRedux/actions/transfer";
import AlertDialogSlide from "../AlertDialogSlide";
import { showLoader } from "../../appRedux/actions/common";
import { useNavigate } from "react-router-dom";

const ListActionView = ({ diffTime, transferId, queryInfo, status }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [alertDialog, setAlertDialog] = useState(false);

  const onHandleAgree = () => {
    dispatch(showLoader());
    dispatch(deleteTransfers({ transferId, queryInfo }));
  };
  const AlertProps = {
    open: alertDialog,
    title: "Are you sure?",
    handleClose: () => {
      setAlertDialog(false);
    },
    handleAgree: onHandleAgree,
  };
  return (
    <>
      <Button
        color={`${diffTime.status === "error" ? "gray" : "lightBlue"}`}
        className="py-1 capitalize   mx-2 "
        buttonType="filled"
        onClick={() => {
          // console.log({ status, transferId });
          if (status !== "Process" && status !== "Banned") {
            navigate("/history/" + transferId);
          }
        }}
        disabled={status === "Process" || status === "Banned" ? true : false}
      >
        View
      </Button>
      <Button
        // color="red"
        color={`${diffTime.status === "error" ? "gray" : "red"}`}
        className="py-1 my-1 capitalize mx-2"
        buttonType="filled"
        onClick={() => {
          if (status !== "Process") {
            setAlertDialog(true);
          }
        }}
        disabled={status === "Process" ? true : false}
      >
        Delete
      </Button>
      <AlertDialogSlide>
        <div {...AlertProps}>{`Are you sure want to delete this transfer?`}</div>
      </AlertDialogSlide>
    </>
  );
};

export default ListActionView;
