import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { memberSignOut } from "../appRedux/actions/auth";
import { showLoader } from "../appRedux/actions/common";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export default function LogoutDialog({ open, handleAgree, handleClose }) {
  const dispach = useDispatch();
  // const [open, setOpen] = React.useState(false);
  const { member, accessLevel } = useSelector((state) => ({
    member: state.auth.member,
    accessLevel: state.auth.member ? state.auth.member.accessLevel : 0,
  }));
  // console.log("LogoutDialog------------", { open, handleAgree, handleClose });
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleAgree = () => {
  //   dispach(showLoader());
  //   dispach(memberSignOut());
  //   setOpen(false);
  // };

  return (
    <div>
      {/* <Button fullWidth color="inherit" variant="outlined" onClick={handleClickOpen}>
        Logout
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Log out</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to logout from TransferItNow?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="inherit">
            Disagree
          </Button>
          <Button onClick={() => handleAgree()} color="error">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
