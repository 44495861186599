import {
  DROP_ZONE_PUSH,
  DROP_ZONE_CREATE_SUCCESS,
  DROP_ZONE_REMOVE_OBJECT,
  DROP_ZONE_STATUS,
  DROP_ZONE_UPDATE_SUCCESS,
  DROP_ZONE_DELETE_SUCCESS,
  DROP_ZONE_FILTER_HANDLING,
  DROP_ZONE_SEARCH_NAME,
} from "../actionTypes/dropZone";
import { USER_CONTACT_SELECTED_TUCHED } from "../actionTypes/userContacts";
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER, ON_HIDE_LOADER } from "../actionTypes/common";
import { memberSignOut } from "./auth";
// import AllDataService from '../services/all.service';
import axiosHttp from "../services/axiosCommon";

export const setFileData = (list) => async (dispatch) => {
  dispatch({
    type: DROP_ZONE_PUSH,
    payload: list,
  });
  dispatch({
    type: USER_CONTACT_SELECTED_TUCHED,
    payload: "transferData",
  });
};
export const statusDropZone = (status) => async (dispatch) => {
  dispatch({
    type: DROP_ZONE_STATUS,
    payload: status,
  });
};
export const removeObjectDropZone = (infoData) => async (dispatch) => {
  //console.log({ infoData });
  dispatch({
    type: DROP_ZONE_REMOVE_OBJECT,
    payload: infoData,
  });
};
export const updateDropZone = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  //console.log({ infoData }, "------------");
  try {
    const result = await axiosHttp.put("/dropZone", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/dropZone/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: DROP_ZONE_UPDATE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteDropZone = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.delete("/dropZone", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/dropZone/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: DROP_ZONE_DELETE_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: DROP_ZONE_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: DROP_ZONE_FILTER_HANDLING,
    payload: info,
  });
};
