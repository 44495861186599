//   column_Handling;
// _id: '60ef79f8d685ff572cdb8242';
// description: 'Ansal, Golf Link -2';
// landlordId: null;
// monthlyRent: 12000;
// name: 'AD-172, Ansal, Golf Link -2';
// otherPayment: 2000;
// otherPaymentType: 'PER';
// paymentType: 'INR';
// postalCode: 203306;
// totalMonthlyRent: 14000;
export const COLUMN_HANDLING = [
  {
    id: 'ResidenceNumber',
    key: 'residenceInfo',
    childKey: 'number',
    label: 'Residence Number',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  {
    id: 'TenantName',
    key: 'tenantInfo',
    childKey: 'name',
    label: 'Tenant Name',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  {
    id: 'TenantPhoneNumber',
    key: 'tenantInfo',
    childKey: 'phoneNumber',
    label: 'Tenant Phone Number',
    align: 'left',
    pading: '0 3',
    visibility: true,
    isFilter: true
  },
  {
    id: 'MonthlyRent',
    key: 'residenceInfo',
    childKey: 'totalMonthlyRent',
    label: 'Monthly Rent',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'DepositAmount',
    key: 'depositAmount',
    label: 'Deposit Amount',
    align: 'center',
    visibility: true,
    isFilter: true
  },
  {
    id: 'OccupiedDate',
    key: 'occupiedDate',
    label: 'Occupied Date',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'Status',
    key: 'occupied',
    label: 'Status',
    align: 'left',
    visibility: true,
    isFilter: true
  },
  {
    id: 'CreatedAt',
    key: 'createdAt',
    label: 'Created At',
    align: 'left',
    visibility: false,
    isFilter: true
  },
  {
    id: 'Action',
    key: 'action',
    label: 'Action',
    align: 'left',
    visibility: true,
    isFilter: false
  }
];
