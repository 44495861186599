import React from "react";
import PropTypes from "prop-types";
import useWindowDimensions from "../../components/common/WindowDimensions";
import { useSelector } from "react-redux";
import { Wave } from "react-animated-text";
import { Heading3 } from "@material-tailwind/react";

import Backdrop from "@mui/material/Backdrop";
export default function Downloading() {
  const { download } = useSelector((state) => ({
    download: state.common.download,
  }));
  const { height, width } = useWindowDimensions();

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={download}
    >
      <Heading3 className={`pt-[${(height - 50) / 2}]`} color="lightBlue">
        <Wave text="Progressing..." effect="stretch" effectChange={3.0} />
      </Heading3>
    </Backdrop>
  );
}
