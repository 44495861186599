import React from "react";
import Icon from "@material-tailwind/react/Icon";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-tailwind/react/Button";
import { getFileType, bytesToSize, getTotalSize } from "../../utils/fileConstant";

import DownloadIcon from "@mui/icons-material/Download";
import { downloadTransfer } from "../../appRedux/actions/transfer";
const DataList = ({ _DATA, downloadFile, transferId, token }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="flex flex-wrap  ">
        {_DATA.currentData().map((info) => {
          //console.log({ info }, "----------");
          let isFolder = info.type === "Folder" || info.type === "folder" ? true : false;
          var status = isFolder ? { type: "folder", icon: "folder" } : getFileType(info);
          return (
            <div key={info._id + "DataList-1"} className={`  w-full md:w-6/12  px-2 py-3 `}>
              <Button
                color="transparent"
                className=" text-xl   text-black capitalize w-full  px-1 py-2 item-start justify-start  text-left w-full bg-white rounded-xl overflow-hdden shadow-md mb-0"
                size="2xl"
                buttonType="button"
                ripple="light"
              >
                <span
                  className={`${status.family ? "px-2" : "px-1"}`}
                  id={status.family ? "imageIcon" : ""}
                >
                  <Icon
                    family={status.family}
                    name={isFolder ? "folder" : status.icon}
                    size="2xl"
                    color="black"
                  />
                </span>
                <span className="   font-medium text-sm " style={{ textTransform: "none" }}>
                  {info.name.length > 20 ? info.name.slice(0, 20) + "..." : info.name}
                </span>
                <span className="text-gray-600 px-2 text-xs  cursor-pointer">
                  ({bytesToSize(info.size)})
                </span>
              </Button>
              <span
                className="text-gray-600 px-1 text-xs float-right cursor-pointer"
                // onClick={() => dispatch(removeObjectDropZone(info))}
                style={{ position: "relative", top: "-32px", right: "7px" }}
                // onClick={() => {
                //   if (transferId && token) {
                //     dispatch(
                //       downloadTransfer({
                //         transferId,
                //         token,
                //         fileId: info._id,
                //       })
                //     );
                //   }
                //   downloadFile(info);
                // }}
              >
                {/* <DownloadIcon fontSize="small" /> */}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DataList;
