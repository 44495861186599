import React from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";
import { Label } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    // minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function MessageValue() {
  const classes = useStyles();
  const { transferInfo } = useSelector((state) => ({
    transferInfo: state.transfer.transferInfo,
  }));
  return (
    <>
      <Paper className={`${classes.root} `}>
        <Label color={"black"}>Message</Label>
        <div
          className="mb-1 mt-0 px-4 pb-4 pt-2 bg-bb w-full m-h-50"
          id="removed"
          style={{ minHeight: "155px" }}
        >
          <p>{transferInfo.message}</p>
          {/* <TextField
            id="standard-multiline-static"
            multiline
            type="text"
            size="small"
            fullWidth
            value={transferInfo.message}
            minRows={5}
            maxRows={7}
            variant="standard"
            readOnly={true}
            disabled
          /> */}
        </div>
      </Paper>
    </>
  );
}
