import {
  ASSIGNED_LIST,
  ASSIGNED_CREATE_SUCCESS,
  ASSIGNED_LIST_SUCCESS,
  ASSIGNED_INFO_SUCCESS,
  ASSIGNED_FILTER_HANDLING,
  ASSIGNED_SEARCH_NAME,
  ASSIGNED_UPDATE_SUCCESS,
} from "../actionTypes/assigned";

import { COLUMN_HANDLING } from "../../_mocks_/assigned";
import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";

const INIT_STATE = {
  assignedList: [],
  isSuccess: false,
  assigned: [],
  assignedInfo: {},
  categories: [],
  queryInfo: {
    page: 0,
    limit: 10,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: COLUMN_HANDLING,
  searchName: "",
  searchStatus: false,
  assignedType: "Active",
  isUpdate: false,
  isInfo: true,
};

function assignedReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        undefined,
      };
    }
    case ASSIGNED_LIST: {
      //console.log({ payload });
      return {
        ...state,
        assigned: payload,
      };
    }
    case ASSIGNED_LIST_SUCCESS: {
      return {
        ...state,
        searchStatus: false,
        assignedList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.totalRecords,
        isSuccess: false,
        isCategory: true,
        isInfo: true,
        // page: payload.queryInfo.page || 0
      };
    }

    case ASSIGNED_CREATE_SUCCESS: {
      return {
        ...state,
        assignedList: [payload.data, ...state.assignedList],
        isSuccess: true,
        isInfo: true,
      };
    }

    case ASSIGNED_UPDATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        assignedList: state.assignedList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        assignedInfo: payload.data,
        isSuccess: true,
        isUpdate: true,
        isInfo: true,
      };
    }

    case ASSIGNED_INFO_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        assignedInfo: payload.data,
        isInfo: false,
      };
    }
    case ASSIGNED_FILTER_HANDLING: {
      //console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        assignedType: payload.assignedType,
      };
    }
    case ASSIGNED_SEARCH_NAME: {
      //console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit },
      };
    }

    default:
      return state;
  }
}

export default assignedReducer;
