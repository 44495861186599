import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";
import {
  ASSOCIATE_CREATE_SUCCESS,
  ASSOCIATE_LIST_SUCCESS,
  ASSOCIATE_INFO_SUCCESS,
  ASSOCIATE_FILTER_HANDLING,
  ASSOCIATE_SEARCH_NAME,
  ASSOCIATE_UPDATE_SUCCESS,
} from "../actionTypes/associate";

const INIT_STATE = {
  associateList: [],
  isSuccess: false,
  associateInfo: {},
  queryInfo: {
    page: 0,
    limit: 10,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [
    {
      id: "Name",
      key: "name",
      label: "Name",
      align: "left",
      pading: "0 3",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Email",
      key: "email",
      label: "Email",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "ContactNumber",
      key: "contactNumber",
      label: "Contact Number",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "IdNumber",
      key: "idNumber",
      label: "ID Number",
      align: "left",
      visibility: false,
      isFilter: true,
    },
    {
      id: "Country",
      key: "country",
      label: "Country",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "City",
      key: "city",
      label: "City",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Address",
      key: "address",
      label: "Address",
      align: "left",
      visibility: false,
      isFilter: true,
    },
    {
      id: "PostalCode",
      key: "postalCode",
      label: "Postal Code",
      align: "left",
      visibility: false,
      isFilter: true,
    },
    {
      id: "Status",
      key: "active",
      label: "Status",
      align: "left",
      visibility: false,
      isFilter: true,
    },
    {
      id: "CreatedAt",
      key: "createdAt",
      label: "Created At",
      align: "left",
      visibility: false,
      isFilter: true,
    },
    {
      id: "Action",
      key: "action",
      label: "Action",
      align: "left",
      visibility: true,
      isFilter: false,
    },
  ],
  searchName: "",
  searchStatus: false,
  associateType: "Active",
  isUpdate: false,
};

function AssociateReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        undefined,
      };
    }
    case ASSOCIATE_LIST_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        associateList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.totalRecords,
        isSuccess: false,
        // page: payload.queryInfo.page || 0
      };
    }

    case ASSOCIATE_CREATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        associateList: [payload.data, ...state.associateList],
        isSuccess: true,
      };
    }

    case ASSOCIATE_UPDATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        associateList: state.associateList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        associateInfo: payload.data,
        isSuccess: true,
        isUpdate: true,
      };
    }

    case ASSOCIATE_INFO_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        associateInfo: payload.data,
        // isSuccess: true
      };
    }
    case ASSOCIATE_FILTER_HANDLING: {
      //console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        associateType: payload.associateType,
      };
    }
    case ASSOCIATE_SEARCH_NAME: {
      //console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit },
      };
    }

    default:
      return state;
  }
}

export default AssociateReducer;
