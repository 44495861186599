import React, { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "../common/Pagination";
import Button from "@material-tailwind/react/Button";

import SendIcon from "@mui/icons-material/Send";
import H5 from "@material-tailwind/react/Heading5";
import { bytesToSize } from "../../utils/fileConstant";

import { selectedDataUserContacts } from "../../appRedux/actions/userContacts";

import {
  DataList,
  PaginationView,
  SubjectValue,
  MessageValue,
  DropZoneAreaInner,
  AddFile,
  AttachFolder,
  SelectView,
  Storage,
  SendButton,
} from ".";
import { getAllUserContacts } from "../../appRedux/actions/userContacts";
import { sendTransfer } from "../../appRedux/actions/transfer";
import { getStorageInfo } from "../../appRedux/actions/storage";

const checkValidation = (newValue, selectedData, transferData, dispatch) => {
  const { values, touched, errors } = selectedData;

  delete values.transferData;
  const { contactIds } = values;
  if ((contactIds.length > 0 || newValue !== null) && transferData.length > 0) {
    return true;
  } else {
    dispatch(
      selectedDataUserContacts({
        touched: {
          ...touched,
          subject: true,
          message: true,
          contactIds: true,
          transferData: true,
        },
        errors: {
          ...errors,
          subject: values.subject.trim() !== "" ? "" : "Subject is required. ",
          message: values.message.trim() !== "" ? "" : "Message is required. ",
          contactIds:
            values.contactIds.length > 0 || newValue !== null
              ? ""
              : "At least one email address is required.",
          transferData: transferData.length > 0 ? "" : "Select at least one file.",
        },
      })
    );

    return false;
  }
};

const View = (props) => {
  const dispatch = useDispatch();

  const [subject, setSubject] = useState({ value: "", error: false });
  const [message, setMessage] = useState({ value: "", error: false });

  const { fileListInfo, numOfFile, numOfFolder, totalSize, selectedData, newValue } = useSelector(
    (state) => ({
      member: state.member.member,
      fileListInfo: state.dropZone.fileListInfo,
      numOfFile: state.dropZone.numOfFile,
      numOfFolder: state.dropZone.numOfFolder,
      totalSize: state.dropZone.totalSize,
      selectedData: state.userContacts.selectedData,
      newValue: state.userContacts.newValue,
    })
  );

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    dispatch(getAllUserContacts());
    dispatch(getStorageInfo());
  }, []);

  const count = Math.ceil(fileListInfo.length / PER_PAGE);
  const _DATA = usePagination(fileListInfo, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  const submitNow = async () => {
    const { values } = selectedData;
    const { contactIds } = values;

    if (await checkValidation(newValue, selectedData, fileListInfo, dispatch)) {
      const formData = new FormData();
      for (var i = 0; i < fileListInfo.length; i++) {
        formData.append("dataFile", fileListInfo[i].files);
      }
      dispatch(
        sendTransfer({
          ...selectedData.values,
          email: newValue || undefined,
          contactIds: contactIds.length > 0 ? contactIds.map((a) => a._id) : [],
          transferData: fileListInfo, // listData,
          numOfFile,
          numOfFolder,
          totalSize,
          totalFiles: numOfFile + numOfFolder,
        })
      );
    }
  };
  const { values, touched, errors } = selectedData;
  // console.log({ count });
  return (
    <div className={`  flex flex-wrap `}>
      <div className="w-full md:w-8/12 px-4  py-1">
        <div className="w-full py-1">
          <div className={`  flex flex-wrap `}>
            {count > 0 && <AddFile fileListInfo={fileListInfo} _DATA={_DATA} />}
          </div>
          <div className={`  flex flex-wrap `}>
            {count > 0 && (
              <div className="flex  w-full items-start justify-start  ">
                <div className="flex ">
                  <H5 color="gray">Our Transfer Files</H5>{" "}
                </div>
                <div className=" flex py-2  ">
                  {" "}
                  &nbsp; &nbsp;
                  <p className={`  text-gray-600   `}>
                    {`${
                      numOfFile > 0
                        ? `${numOfFile} ${numOfFile > 1 ? "Files" : "File"} ${
                            numOfFolder > 0
                              ? `and ${`${numOfFolder} ${numOfFolder > 1 ? "Folders" : "Folder"}`}`
                              : ""
                          } added `
                        : `  ${
                            numOfFolder > 0
                              ? `${`${numOfFolder} ${numOfFolder > 1 ? "Folders" : "Folder"}`}`
                              : "No file "
                          } added `
                    } `}
                  </p>
                  <br />
                  <p className={`  text-gray-600   `}>
                    &nbsp;
                    {` (${bytesToSize(totalSize)})`}
                  </p>
                </div>
              </div>
            )}
          </div>
          {count > 0 ? (
            <DataList fileListInfo={fileListInfo} _DATA={_DATA} />
          ) : (
            <div>
              <DropZoneAreaInner />
              <AttachFolder
                linkView={true}
                // handleClose={() => //console.log("close")}
              />

              <p className="text-red-500 px-4 text-center justify-center">
                {touched.transferData && errors.transferData && errors.transferData !== ""
                  ? errors.transferData
                  : ""}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full md:w-4/12 px-2   ">
        <div className="w-full">
          <SendButton submitNow={() => submitNow()} />

          <div className="w-full py-2">
            <Storage />
          </div>
          <div className="w-full py-2">
            <SelectView />
          </div>
          <div className="w-full py-2">
            <SubjectValue subject={subject} setSubject={setSubject} />
          </div>
          <div className="w-full py-2">
            <MessageValue message={message} setMessage={setMessage} />
          </div>
        </div>
      </div>

      <div className={`  flex flex-wrap   w-full  `}>
        <div className="w-full md:w-8/12 px-4  py-6">
          {count >= 2 && <PaginationView count={count} page={page} handleChange={handleChange} />}
        </div>
        <div className="w-full md:w-4/12 px-2 mx-auto items-center flex justify-center mt-24 lg:mt-0"></div>
      </div>
    </div>
  );
};

export default View;
