import React from "react";

const SetInnerHTML = ({ innerHTML }) => {
  //console.log({ innerHTML });
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: innerHTML,
      }}
    />
  );
};

export default SetInnerHTML;
