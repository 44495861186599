import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";
import { TRANSFER_SELECTED_RESET } from "../actionTypes/transfer";
import {
  USER_CONTACT_CREATE_SUCCESS,
  USER_CONTACT_LIST,
  USER_CONTACT_LIST_SUCCESS,
  USER_CONTACT_INFO_SUCCESS,
  USER_CONTACT_FILTER_HANDLING,
  USER_CONTACT_SEARCH_NAME,
  USER_CONTACT_UPDATE_SUCCESS,
  USER_CONTACT_SELECTED,
  USER_CONTACT_SELECTED_TUCHED,
  USER_CONTACT_EDIT_TABLE,
  USER_CONTACT_SELECTED_CONTACT,
  USER_CONTACT_SELECTED_SET_NEW_VALUE,
} from "../actionTypes/userContacts";

const INIT_STATE = {
  newValue: null,
  userContacts: [],
  userContactList: [],
  selectedList: [],
  selectedData: {
    values: { contactIds: [], subject: "", message: "", transferData: [] },
    errors: { contactIds: "", subject: "", message: "", transferData: "" },
    touched: {
      contactIds: false,
      subject: false,
      message: false,
      transferData: false,
    },
  },
  isSuccess: false,
  userContactInfo: {},
  queryInfo: {
    page: 0,
    limit: 10,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [],
  searchName: "",
  searchStatus: false,
  userContactType: "Active",
  isUpdate: false,
  isUserContact: true,
  editView: {
    id: "",
    status: false,
    isEdit: false,
  },
  columnHandling: [
    {
      id: "Email",
      key: "email",
      // childKey: "email",
      label: "Email",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Name",
      key: "name",
      label: "Name",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "CompanyName",
      key: "companyName",
      label: "Company Name",
      align: "left",
      visibility: true,
      isFilter: true,
    },

    {
      id: "CreatedAt",
      key: "createdAt",
      label: "Created At",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Action",
      key: "action",
      label: "Action",
      align: "center",
      visibility: true,
      isFilter: true,
    },
  ],
};

function userContactReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        newValue: null,
        userContacts: [],
        userContactList: [],
        selectedList: [],
        selectedData: {
          values: {
            contactIds: [],
            subject: "",
            message: "",
            transferData: [],
          },
          errors: {
            contactIds: "",
            subject: "",
            message: "",
            transferData: "",
          },
          touched: {
            contactIds: false,
            subject: false,
            message: false,
            transferData: false,
          },
        },
        isSuccess: false,
        userContactInfo: {},
        queryInfo: {
          page: 0,
          limit: 10,
        },
        totalRecords: 0,
        page: 0,
        columnHandling: [],
        searchName: "",
        searchStatus: false,
        userContactType: "Active",
        isUpdate: false,
        isUserContact: true,
        editView: {
          id: "",
          status: false,
          isEdit: false,
        },
        columnHandling: [
          {
            id: "Email",
            key: "email",
            // childKey: "email",
            label: "Email",
            align: "left",
            visibility: true,
            isFilter: true,
          },
          {
            id: "Name",
            key: "name",
            label: "Name",
            align: "left",
            visibility: true,
            isFilter: true,
          },
          {
            id: "CompanyName",
            key: "companyName",
            label: "Company Name",
            align: "left",
            visibility: true,
            isFilter: true,
          },

          {
            id: "CreatedAt",
            key: "createdAt",
            label: "Created At",
            align: "center",
            visibility: true,
            isFilter: true,
          },
          {
            id: "Action",
            key: "action",
            label: "Action",
            align: "center",
            visibility: true,
            isFilter: true,
          },
        ],
      };
    }
    case USER_CONTACT_SELECTED_SET_NEW_VALUE: {
      return {
        ...state,
        newValue: payload,
      };
    }
    case TRANSFER_SELECTED_RESET: {
      return {
        ...state,
        selectedData: {
          values: {
            contactIds: [],
            subject: "",
            message: "",
            transferData: [],
          },
          errors: {
            contactIds: "",
            subject: "",
            message: "",
            transferData: "",
          },
          touched: {
            contactIds: false,
            subject: false,
            message: false,
            transferData: false,
          },
        },
      };
    }
    // type: ,
    // payload: "transferData",
    case USER_CONTACT_SELECTED_TUCHED: {
      //console.log({ payload });

      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          touched: {
            ...state.selectedData.touched,
            transferData: payload === "transferData" ? true : false,
            // contactIds: payload === "contactIds" ? true : false,
            // subject: payload === "subject" ? true : false,
            // message: payload === "message" ? true : false,
          },
          errors: {
            ...state.selectedData.errors,
            transferData: payload === "transferData" ? "" : "",
            // contactIds: payload === "contactIds" ? true : false,
            // subject: payload === "subject" ? true : false,
            // message: payload === "message" ? true : false,
          },
        },
      };
    }
    case USER_CONTACT_SELECTED_CONTACT: {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          values: {
            ...state.selectedData.values,
            contactIds: state.selectedData.values.contactIds.concat([payload.data]),
          },
          touched: {
            ...state.selectedData.touched,
            contactIds: true,
          },
          errors: {
            ...state.selectedData.errors,
            contactIds: true,
          },
        },
      };
    }
    case USER_CONTACT_LIST: {
      //console.log({ payload });
      return {
        ...state,
        userContacts: payload.data,
        isUserContact: false,
      };
    }
    case USER_CONTACT_SELECTED: {
      // //console.log({ payload, key: Object.keys(payload)[0] }, "contactIds");
      // Object.keys(payload);

      return {
        ...state,
        selectedData: { ...state.selectedData, ...payload },
      };
    }
    case USER_CONTACT_LIST_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        userContactList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.totalRecords,
        isSuccess: false,
        // page: payload.queryInfo.page || 0
      };
    }

    case USER_CONTACT_EDIT_TABLE: {
      //console.log({ payload });
      return {
        ...state,
        editView: payload,
      };
    }
    case USER_CONTACT_CREATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        userContacts: [payload.data, ...state.userContacts],
        isSuccess: true,
        editView: { id: "", status: false, isEdit: false },
      };
    }

    case USER_CONTACT_UPDATE_SUCCESS: {
      //console.log({ payload }, "USER_CONTACT_UPDATE_SUCCESS");
      return {
        ...state,
        userContactList: state.userContactList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        userContactInfo: payload.data,
        isSuccess: true,
        isUpdate: true,
        editView: { id: "", status: false, isEdit: false },
      };
    }

    case USER_CONTACT_INFO_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        userContactInfo: payload.data,
        // isSuccess: true
      };
    }
    case USER_CONTACT_FILTER_HANDLING: {
      //console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        userContactType: payload.userContactType,
      };
    }
    case USER_CONTACT_SEARCH_NAME: {
      //console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 0, limit: state.queryInfo.limit },
      };
    }

    default:
      return state;
  }
}

export default userContactReducer;
