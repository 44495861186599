import React, { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FormAdd } from ".";

import {
  addDialogValue,
  hideDialog,
  removeDialogValue,
  showDialog,
} from "../../appRedux/actions/dialog";
import {
  addUserContact,
  selectedDataUserContacts,
  selectedDataSetNewValue,
} from "../../appRedux/actions/userContacts";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  tag: {
    height: 24,
    position: "relative",
    zIndex: 0,
    // backgroundColor: "gray",
  },
  redTag: {
    color: "#ee4336",
  },
  blueTag: {
    color: "#03a9f4",
  },
});

export default function FreeSoloCreateOptionDialog() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const Combo = useRef();
  // const [open, toggleOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [contactList, setContactList] = React.useState([]);
  const [newValue3, setValue3] = React.useState(null);

  const { open, value, newValue2, userContacts, selectedData } = useSelector((state) => ({
    open: state.dialog.open,
    value: state.dialog.value,
    newValue2: state.userContacts.newValue,
    userContacts: state.userContacts.userContacts,
    selectedData: state.userContacts.selectedData,
  }));
  const { values, touched, errors } = selectedData;
  const { contactIds } = values;

  React.useEffect(() => {
    // setValue(null);
    dispatch(selectedDataSetNewValue(null));
    setContactList(
      userContacts.filter(
        ({ email: email1 }) => !contactIds.some(({ email: email2 }) => email2 === email1)
      )
    );
    // setContactList(userContacts);
  }, [contactIds, userContacts]);
  //console.log({ contactList });
  const filter = createFilterOptions(contactList);

  // console.log({ contactIds });
  return (
    <React.Fragment>
      <Autocomplete
        multiple
        fullWidth
        value={contactIds}
        onChange={(event, newValue, reason) => {
          // const value = new Array(newValue);
          // console.log({ event, newValue, reason });
          let newValueInner =
            typeof newValue === "string"
              ? newValue
              : newValue.length
              ? newValue[newValue.length - 1]
              : {};

          if (typeof newValueInner === "string") {
            dispatch(selectedDataSetNewValue(newValueInner));
            setTimeout(() => {
              // dispatch(addUserContact({ email: newValueInner }));
              dispatch(
                addDialogValue({
                  email: newValueInner,
                  name: "",
                  companyName: "",
                })
              );
            });
          } else if (
            newValueInner &&
            newValueInner !== null &&
            newValue.findIndex((obj) => Object.keys(obj).includes("inputValue")) !== -1
          ) {
            //console.log("newValue---two");
            dispatch(selectedDataSetNewValue(newValueInner.inputValue));
            // dispatch(addUserContact({ email: newValueInner.inputValue }));
            dispatch(
              addDialogValue({
                email: newValueInner.inputValue,
                name: "",
                companyName: "",
              })
            );
            // });
          } else if (newValueInner === null) {
            //console.log("newValue---three");
            // setValue(newValue);
            dispatch(selectedDataSetNewValue(null));
          } else {
            //console.log("newValue---four");
            // setValue(newValue);
            dispatch(selectedDataSetNewValue(newValueInner.email));
            // selectedDataUserContacts
            dispatch(
              selectedDataUserContacts({
                values: {
                  ...values,
                  contactIds: newValue,
                },
                errors: {
                  ...errors,
                  contactIds: newValue.length ? "" : "At least one email address is required.",
                },
                touched: { ...touched, contactIds: true },
              })
            );
            dispatch(selectedDataSetNewValue(null));
            // dispatch(removeDialogValue());
          }
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={"Chip" + option.email}
              classes={{
                root: classNames(classes.tag, {
                  [classes.redTag]: option.error,
                  [classes.blueTag]: !option.error,
                }),
              }}
              variant="outlined"
              label={`${option.email}`}
              {...getTagProps({ index })}
            />
          ))
        }
        filterOptions={(options, params) => {
          //console.log({ options, params });
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              email: `Add New Email "${params.inputValue}"`,
            });
          }

          //console.log({ filtered, options, params });
          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={contactList || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.email;
        }}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li key={"li-" + option._id ? option._id : option.email} {...props}>
            {option.email}
          </li>
        )}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => {
          // console.log({ params });
          const { inputProps } = params;
          // dispatch(selectedDataSetNewValue(inputProps.value));
          return (
            <TextField
              // key={""}
              fullWidth
              {...params}
              // placeholder={"email"}
              // inputProps={{ ...params.inputProps, value: newValue3 }}
              value={newValue2}
              onChange={(e) => {
                dispatch(selectedDataSetNewValue(e.target.value));
                // console.log({ value: e.target.value });
                // setValue3(e.target.value);
                // dispatch(
                //   addDialogValue({
                //     email: e.target.value,
                //     name: "",
                //     companyName: "",
                //   })
                // );
              }}
            />
          );
        }}
      />
      {/* 
      <Autocomplete
        // multiple={true}
        ref={Combo}
        fullWidth
        value={newValue2 === null ? "" : newValue2 || ""}
        onChange={(event, newValue) => {
          //console.log({ newValue, typeof: typeof newValue });
          if (typeof newValue === "string") {
            dispatch(selectedDataSetNewValue(newValue));
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              dispatch(
                addDialogValue({
                  email: newValue,
                  name: "",
                  companyName: "",
                })
              );
              dispatch(showDialog());
            });
          } else if (newValue && newValue !== null && newValue.inputValue) {
            //console.log("newValue---two");
            dispatch(selectedDataSetNewValue(newValue.inputValue));
            dispatch(
              addDialogValue({
                email: newValue.inputValue,
                name: "",
                companyName: "",
              })
            );
            dispatch(showDialog());
            // toggleOpen(true);
            // setDialogValue({
            //   title: newValue.inputValue,
            //   year: "",
            // });
          } else if (newValue === null) {
            //console.log("newValue---three");
            // setValue(newValue);
            dispatch(selectedDataSetNewValue(null));
          } else {
            //console.log("newValue---four");
            // setValue(newValue);
            dispatch(selectedDataSetNewValue(newValue.email));
            // selectedDataUserContacts
            dispatch(
              selectedDataUserContacts({
                values: {
                  ...values,
                  contactIds: values.contactIds.concat(newValue),
                },
                errors: {
                  ...errors,
                  contactIds: values.contactIds.concat(newValue).length
                    ? ""
                    : "At least one email address is required.",
                },
                touched: { ...touched, contactIds: true },
              })
            );
            dispatch(selectedDataSetNewValue(null));
            // dispatch(removeDialogValue());
          }
        }}
        // inputValue={newValue2}
        filterOptions={(options, params) => {
          //console.log({ options, params });
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              email: `Add New Email "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={contactList || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.email;
        }}
        selectOnFocus
        // clearOnEscape
        // onBlur={onBlur}
        // clearOnBlur
        // clearOnChange
        // clearText={newValue2}
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.email}</li>}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => {
          //console.log({ params });
          return (
            <TextField
              fullWidth
              {...params}
              // inputProps={{ ...params.inputProps, value: newValue3 }}
              // value={newValue3}
              // onChange={(e) => {
              //   //console.log({ value: e.target.value });
              //   setValue3(e.target.value);
              //   // dispatch(
              //   //   addDialogValue({
              //   //     email: e.target.value,
              //   //     name: "",
              //   //     companyName: "",
              //   //   })
              //   // );
              // }}
            />
          );
        }}
      /> */}
      <Dialog
        open={open}
        onClose={() => {
          dispatch(selectedDataSetNewValue(null));
          dispatch(hideDialog());
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <FormAdd value={value} />
      </Dialog>
    </React.Fragment>
  );
}
