import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../components/common/Header";
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TablePagination,
} from "@material-ui/core";
// components
import {
  ListView,
  ListToolbar,
  ListViewReceived,
} from "../../../components/History";
import { getTransfers } from "../../../appRedux/actions/transfer";

export default function History() {
  const dispatch = useDispatch();

  const {
    learnerTypes,
    isUpdate,
    queryInfo,
    page,
    limit,
    totalRecords,
    received,
    // recStatus
  } = useSelector((state) => ({
    isUpdate: state.transfer.isUpdate,
    queryInfo: state.transfer.queryInfo,
    page: state.transfer.queryInfo.page,
    limit: state.transfer.queryInfo.limit,
    totalRecords: state.transfer.totalRecords,
    received: state.transfer.queryInfo.received,
    // recStatus: state.transfer.queryInfo.recStatus,
  }));

  useEffect(() => {
    if (isUpdate) {
      // dispatch(initURL("#"));
      dispatch(getTransfers({ ...queryInfo }));
    } else {
      // dispatch(initURL("#"));
      dispatch(getTransfers({ ...queryInfo, page: 0, limit: 10 }));
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getTransfers({ ...queryInfo, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch();
    getTransfers({ ...queryInfo, page: 0, limit: event.target.value });
  };
  return (
    <main>
      <Header pageName="History" />
      <section className="relative py-16 mt-30 bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="  min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-6">
              {/* <Card > */}
              <ListToolbar />

              {received ? <ListViewReceived /> : <ListView />}

              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={totalRecords}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* </Card> */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
