import { getUserAnalytics } from "../appRedux/actions/user";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../components/common/Header";
import Content from "../components/profile/Content";

export default function Profile() {
  const dispatch = useDispatch();
  // /profile/analytics
  useEffect(() => {
    dispatch(getUserAnalytics());
  }, []);
  return (
    <main>
      <Header pageName={"Profile"} />
      <Content />
    </main>
  );
}
