import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "../../../components/common/Pagination";
import { downloadTransfer, getDetailsInfo } from "../../../appRedux/actions/transfer";
import { useParams } from "react-router-dom";
import Button from "@material-tailwind/react/Button";

import Image from "@material-tailwind/react/Image";
import {
  DataList,
  PaginationView,
  ChipsValue,
  SubjectValue,
  MessageValue,
} from "../../../components/Details";
import Header from "../../../components/common/Header";
import H5 from "@material-tailwind/react/Heading5";
import H6 from "@material-tailwind/react/Heading6";
import { bytesToSize } from "../../../utils/fileConstant";
import AWS from "aws-sdk";
import axios from "axios";
import fileDownload from "js-file-download";
import DownloadIcon from "@mui/icons-material/Download";
import async from "async";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import fs from "fs";
import { Wave } from "react-animated-text";
import { setDownload } from "../../../appRedux/actions/common";
import {
  CONTABO_STORAGE_BUCKET_URL,
  ACCESS_KEY_HERE,
  SECRET_ACCESS_KEY_HERE,
  BUCKET_NAME_HERE,
  HTTP_OPTIONS,
} from "../../../utils/config";

import NoDataFound from "../../../assets/img/no-data.png";

const S3_BUCKET = BUCKET_NAME_HERE;
AWS.config.update({
  endpoint: CONTABO_STORAGE_BUCKET_URL,
  accessKeyId: ACCESS_KEY_HERE,
  secretAccessKey: SECRET_ACCESS_KEY_HERE,
  s3BucketEndpoint: true,
  signatureVersion: "v4",
  maxRetries: 2,
  httpOptions: HTTP_OPTIONS,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  // region: REGION,
});
const View = (props) => {
  const dispatch = useDispatch();
  const { transferId, contactId } = useParams();
  const { fileListInfo, transferDetailsInfo } = useSelector((state) => ({
    transferDetailsInfo: state.transfer.transferDetailsInfo,
    fileListInfo:
      state.transfer.transferDetailsInfo && state.transfer.transferDetailsInfo._id
        ? state.transfer.transferDetailsInfo.transferFiles
        : [],
    numOfFile: state.dropZone.numOfFile,
    numOfFolder: state.dropZone.numOfFolder,
    totalSize: state.dropZone.totalSize,
    selectedData: state.userContacts.selectedData,
    listData: state.dropZone.listData,
  }));
  let [page, setPage] = useState(1);
  const PER_PAGE = 12; //Object.keys(fileListInfo).length; //
  const count = Math.ceil(fileListInfo.length / PER_PAGE);
  const _DATA = usePagination(fileListInfo, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    dispatch(getDetailsInfo({ transferId, contactId }));
  }, []);
  const downloadAll = () => {
    dispatch(setDownload(true));
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "TransferItNow.zip";
    async.mapSeries(
      fileListInfo,
      function (fileInfo, next) {
        const { alteredName, name, type, userId } = fileInfo;
        var keyName = userId + "/" + alteredName;
        var headParams = { Bucket: S3_BUCKET, Key: keyName };
        var urlParams = {
          Bucket: S3_BUCKET,
          Key: keyName,
          Expires: 1000,
        };
        myBucket.headObject(headParams, function (err, data) {
          if (err) {
            next(err);
          } // an error occurred
          else {
            myBucket.getSignedUrl("getObject", urlParams, function (err, url) {
              if (err) {
                next(err);
              } else {
                var link = document.createElement("a");
                link.download = type === "Folder" ? name + ".zip" : name;
                link.href = url;
                link.click();
                setTimeout(() => {
                  next(null);
                }, 10);
                // JSZipUtils.getBinaryContent(url, function (err, data) {
                //   if (err) {
                //     next(err);
                //   } else {
                //     zip.file(type === "Folder" ? name + ".zip" : name, data, {
                //       binary: true,
                //     });

                //     next(null);
                //   }
                // });
              }
            });
          } // successful response
        });
      },
      function (err, result) {
        dispatch(setDownload(false));
        // zip.generateAsync({ type: "blob" }).then(function (content) {
        //   saveAs(content, zipFilename);
        //   dispatch(setDownload(false));
        // });
      }
    );
  };

  const downloadFile = (info) => {
    const { alteredName, name, type, userId } = info;
    var keyName = userId + "/" + alteredName;
    var headParams = { Bucket: S3_BUCKET, Key: keyName };
    var urlParams = {
      Bucket: S3_BUCKET,
      Key: keyName,
      Expires: 1000,
    };
    myBucket.headObject(headParams, function (err, data) {
      if (err) {
        dispatch(setDownload(false));
      } // an error occurred
      else {
        myBucket.getSignedUrl("getObject", urlParams, function (err, url) {
          if (err) {
            dispatch(setDownload(false));
          } else {
            var link = document.createElement("a");
            link.download = type === "Folder" ? name + ".zip" : name;
            link.href = url;
            link.click();
            dispatch(setDownload(false));
            // handleDownload(url, type === "Folder" ? name + ".zip" : name);
          }
        });
      } // successful response
    });
  };
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        dispatch(setDownload(false));
        fileDownload(res.data, filename);
      })
      .catch((e) => {
        dispatch(setDownload(false));
      });
  };
  return (
    <main>
      <Header pageName="Downloads" />
      <section className="relative py-16 mt-30 bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-10 py-10 min-h-[440px]  ">
              {/* hello.. */}
              {transferDetailsInfo && transferDetailsInfo.active && !transferDetailsInfo.expire ? (
                <div className={`  flex flex-wrap `}>
                  <div className="w-full md:w-8/12 px-4  py-1">
                    <div className={`  flex flex-wrap `}>
                      <div className="flex  w-full items-start justify-start  ">
                        <div className="  flex ">
                          <H5 color="gray">Transfer Files</H5>{" "}
                        </div>
                        <div className=" flex py-2  ">
                          {" "}
                          &nbsp; &nbsp;
                          <p className={`  text-gray-600   `}>
                            {`${
                              transferDetailsInfo.numOfFile > 0
                                ? `${transferDetailsInfo.numOfFile} ${
                                    transferDetailsInfo.numOfFile > 1 ? "Files" : "File"
                                  } ${
                                    transferDetailsInfo.numOfFolder > 0
                                      ? `and ${`${transferDetailsInfo.numOfFolder} ${
                                          transferDetailsInfo.numOfFolder > 1
                                            ? "Folders "
                                            : "Folder "
                                        }`}`
                                      : ""
                                  }  `
                                : `  ${
                                    transferDetailsInfo.numOfFolder > 0
                                      ? `${`${transferDetailsInfo.numOfFolder} ${
                                          transferDetailsInfo.numOfFolder > 1
                                            ? "Folders "
                                            : "Folder "
                                        }`}`
                                      : "No file "
                                  }  `
                            } `}
                          </p>
                          <br />
                          <p className={`  text-gray-600   `}>
                            &nbsp;
                            {` (${bytesToSize(transferDetailsInfo.totalSize)})`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full py-1">
                      {/* <Card > */}

                      {/* dispatch(downloadTransfer({ transferId, contactId })); */}
                      <DataList
                        downloadFile={downloadFile}
                        _DATA={_DATA}
                        contactId={contactId}
                        transferId={transferId}
                      />

                      {count >= 2 && (
                        <PaginationView count={count} page={page} handleChange={handleChange} />
                      )}
                      {/* </Card> */}
                    </div>
                  </div>

                  <div className="w-full md:w-4/12 px-2   ">
                    <div className="w-full">
                      <div className="flex  w-full items-end justify-end bg-bb ">
                        {/*  h-[2.8rem] */}
                        <Button
                          color="cyan"
                          buttonType="filled"
                          size="sm"
                          ripple="dark"
                          className="  mx-2"
                          onClick={() => {
                            dispatch(downloadTransfer({ transferId, contactId }));
                            downloadAll();
                          }}
                        >
                          Download All
                          <DownloadIcon
                            fontSize="small"
                            style={{
                              transform: "rotateY(180deg)",
                              width: "24px",
                              height: "24px",
                              float: "right",
                            }}
                          />
                        </Button>
                      </div>

                      {/* <div className="w-full py-2">
                     <ChipsValue />
                   </div> */}
                      {transferDetailsInfo.subject && transferDetailsInfo.subject !== "" && (
                        <div className="w-full py-2">
                          <SubjectValue />
                        </div>
                      )}
                      {transferDetailsInfo.message && transferDetailsInfo.message !== "" && (
                        <div className="w-full py-2">
                          <MessageValue />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center ">
                  <Image alt="Card Image" src={NoDataFound} className=" m-auto" />

                  <H6 className="text-center">
                    <Wave text="This download link expired." effect="stretch" effectChange={3.0} />
                  </H6>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default View;
