import Header from "../components/landing/Header";
import WorkingSection from "../components/landing/WorkingSection";
import TeamSection from "../components/landing/TeamSection";
import ContactSection from "../components/landing/ContactSection";

export default function Landing() {
  return (
    <>
      <main>
        <Header />
        <WorkingSection />
        {/* <TeamSection /> */}
        <ContactSection />
      </main>
    </>
  );
}
