import Header from "../components/common/Header";
import WorkingSection from "../components/aboutUs/WorkingSection";

export default function AboutUs() {
  return (
    <main>
      <Header pageName={"About Us"} />
      <section className="relative py-16 bg-gray-100">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-6">
              <WorkingSection />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
