import { Button } from "@material-tailwind/react";
import { initURL } from "../../appRedux/actions/common";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useN } from "react-router-dom";
import { Link, useNavigate, useLocation } from "react-router-dom";
export default function SimpleFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const yearOf = new Date().getFullYear().toString();
  return (
    <>
      <footer className="pt-8 pb-6">
        <div className="container max-w-7xl mx-auto px-4">
          <div className="flex flex-wrap justify-center gap-4 md:justify-between">
            <ul className="list-unstyled flex gap-8">
              <li>
                <Button
                  color="transparent"
                  className="py-0 px-2.5  ml-3 text-xl flex items-center text-white capitalize hover:text-light-blue-700"
                  size="2xl"
                  buttonType="link"
                  ripple="light"
                  onClick={() => {
                    dispatch(initURL("/home"));
                    navigate("/home", { replace: true });
                  }}
                  style={{ padding: 0 }}
                >
                  <span className="text-sm  ">Home</span>
                </Button>
                {/* <Link
                  to="/home"
                  rel="noreferrer"
                  className="text-white font-medium block pb-2 text-sm"
                >
                  Home
                </Link> */}
              </li>
              <li>
                {/* <Link
                  to="/frequently-asked-questions"
                  rel="noreferrer"
                  className="text-white font-medium block pb-2 text-sm"
                >
                  FAQ
                </Link> */}
                <Button
                  color="transparent"
                  className="py-0 px-2.5  ml-3 text-xl flex items-center text-white capitalize hover:text-light-blue-700"
                  size="2xl"
                  buttonType="link"
                  ripple="light"
                  onClick={() => {
                    dispatch(initURL("/frequently-asked-questions"));
                    navigate("/frequently-asked-questions", { replace: true });
                  }}
                  style={{ padding: 0 }}
                >
                  <span className="text-sm  ">FAQ</span>
                </Button>
              </li>
              <li>
                {/* <Link
                  to="/contact-us"
                  rel="noreferrer"
                  className="text-white font-medium block pb-2 text-sm"
                >
                  Contact Us
                </Link> */}
                <Button
                  color="transparent"
                  className="py-0 px-2.5  ml-3 text-xl flex items-center text-white capitalize hover:text-light-blue-700"
                  size="2xl"
                  buttonType="link"
                  ripple="light"
                  onClick={() => {
                    dispatch(initURL("/contact-us"));
                    navigate("/contact-us", { replace: true });
                  }}
                  style={{ padding: 0 }}
                >
                  <span className="text-sm  ">Contact Us</span>
                </Button>
              </li>
            </ul>

            <div className="text-sm text-white font-medium">
              Copyright ©{" "}
              {yearOf === "2022" ? yearOf : "2022-" + yearOf.slice(2)}{" "}
              TransferItNow by{" "}
              <a
                href="http://www.gaviralgamtec.com/"
                target="_blank"
                className="text-white"
              >
                Gaviral Gamtec Private Limited
              </a>
              .
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
