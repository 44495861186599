export const DROP_ZONE_CREATE = "DROP_ZONE_CREATE";
export const DROP_ZONE_CREATE_SUCCESS = "DROP_ZONE_CREATE_SUCCESS";
export const DROP_ZONE_CREATE_ERROR = "DROP_ZONE_CREATE_ERROR";
export const DROP_ZONE_LIST = "DROP_ZONE_LIST";
export const DROP_ZONE_REMOVE_OBJECT = "DROP_ZONE_REMOVE_OBJECT";
export const DROP_ZONE_LIST_ERROR = "DROP_ZONE_LIST_ERROR";
export const DROP_ZONE_INFO = "DROP_ZONE_INFO";
export const DROP_ZONE_INFO_SUCCESS = "DROP_ZONE_INFO_SUCCESS";
export const DROP_ZONE_INFO_ERROR = "DROP_ZONE_INFO_ERROR";
export const DROP_ZONE_UPDATE_SUCCESS = "DROP_ZONE_UPDATE_SUCCESS";
export const DROP_ZONE_UPDATE_ERROR = "DROP_ZONE_UPDATE_ERROR";
export const DROP_ZONE_DELETE_SUCCESS = "DROP_ZONE_DELETE_SUCCESS";
export const DROP_ZONE_FILTER_HANDLING = "DROP_ZONE_FILTER_HANDLING";
export const DROP_ZONE_SEARCH_NAME = "DROP_ZONE_SEARCH_NAME";
export const DROP_ZONE_PUSH = "DROP_ZONE_PUSH";
export const DROP_ZONE_STATUS = "DROP_ZONE_STATUS";
