export const SIGNIN_MEMBER = "SIGNIN_MEMBER";
export const SIGNIN_MEMBER_SUCCESS = "SIGNIN_MEMBER_SUCCESS";
export const SIGNIN_MEMBER_ERROR = "SIGNIN_MEMBER_ERROR";
export const SIGNOUT_MEMBER = "SIGNOUT_MEMBER";
export const SIGNOUT_MEMBER_SUCCESS = "SIGNOUT_MEMBER_SUCCESS";
export const SIGNUP_MEMBER = "SIGNUP_MEMBER";
export const SIGNUP_MEMBER_SUCCESS = "SIGNUP_MEMBER_SUCCESS";
export const ALL_CLEAR_SUCCESS = "ALL_CLEAR_SUCCESS";
export const FIND_MEMBER_SUCCESS = "FIND_MEMBER_SUCCESS";
export const ON_PING = "ON_PING";
export const ON_PING_SUCCESS = "ON_PING_SUCCESS";

export const SIGNIN_FACEBOOK_MEMBER = "SIGNIN_FACEBOOK_MEMBER";
export const SIGNIN_FACEBOOK_MEMBER_SUCCESS = "SIGNIN_FACEBOOK_MEMBER_SUCCESS";
export const SIGNIN_GITHUB_MEMBER = "SIGNIN_GITHUB_MEMBER";
export const SIGNIN_GITHUB_MEMBER_SUCCESS = "SIGNIN_GITHUB_MEMBER_SUCCESS";
export const SIGNIN_GOOGLE_MEMBER = "SIGNIN_GOOGLE_MEMBER";
export const SIGNIN_GOOGLE_MEMBER_SUCCESS = "SIGNIN_GOOGLE_MEMBER_SUCCESS";
export const SIGNIN_TWITTER_MEMBER = "SIGNIN_TWITTER_MEMBER";
export const SIGNIN_TWITTER_MEMBER_SUCCESS = "SIGNIN_TWITTER_MEMBER_SUCCESS";
export const SIGNIN_MICROSOFT_MEMBER = "SIGNIN_MICROSOFT_MEMBER";
export const SIGNIN_MICROSOFT_MEMBER_SUCCESS = "SIGNIN_MICROSOFT_MEMBER_SUCCESS";

export const SIGNOUT_AUTH_MEMBER = "SIGNOUT_AUTH_MEMBER";
export const SIGNOUT_AUTH_MEMBER_SUCCESS = "SIGNOUT_AUTH_MEMBER_SUCCESS";
export const AUTH_LEVEL = "AUTH_LEVEL";
export const MEMBER_CONTACT_US = "MEMBER_CONTACT_US";
export const MEMBER_CONTACT_US_SUCCESS = "MEMBER_CONTACT_US_SUCCESS";
