export const ASSIGNED_CREATE = 'ASSIGNED_CREATE';
export const ASSIGNED_CREATE_SUCCESS = 'ASSIGNED_CREATE_SUCCESS';
export const ASSIGNED_CREATE_ERROR = 'ASSIGNED_CREATE_ERROR';
export const ASSIGNED_LIST = 'ASSIGNED_LIST';
export const ASSIGNED_LIST_SUCCESS = 'ASSIGNED_LIST_SUCCESS';
export const ASSIGNED_LIST_ERROR = 'ASSIGNED_LIST_ERROR';
export const ASSIGNED_INFO = 'ASSIGNED_INFO';
export const ASSIGNED_INFO_SUCCESS = 'ASSIGNED_INFO_SUCCESS';
export const ASSIGNED_INFO_ERROR = 'ASSIGNED_INFO_ERROR';
export const ASSIGNED_UPDATE_SUCCESS = 'ASSIGNED_UPDATE_SUCCESS';
export const ASSIGNED_UPDATE_ERROR = 'ASSIGNED_UPDATE_ERROR';
export const ASSIGNED_DELETE_SUCCESS = 'ASSIGNED_DELETE_SUCCESS';
export const ASSIGNED_FILTER_HANDLING = 'ASSIGNED_FILTER_HANDLING';
export const ASSIGNED_SEARCH_NAME = 'ASSIGNED_SEARCH_NAME';
