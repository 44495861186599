import Header from "../../components/common/Header";
import FAQView from "../../components/InformedPage/faq";

export default function FAQ() {
  return (
    <main>
      <Header pageName="Frequently Asked Questions" />
      <section className="relative mt-30 py-16 bg-gray-100 ">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="p-10 min-h-[340px] mx-20 my-10">
              <FAQView />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
