import React, { useState } from "react";

import Button from "@material-tailwind/react/Button";

import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertStorageDialog from "../AlertStorageDialog";
import { sizeToBytes } from "../../utils/fileConstant";
const SendButton = ({ submitNow }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { totalSize, storagePlan, useStorage, accessData } = useSelector((state) => ({
    totalSize: state.dropZone.totalSize,
    useStorage: state.auth.storage ? state.auth.storage.useStorage : 0,
    storagePlan: state.auth.storage ? state.auth.storage.storagePlan : {},
    accessData:
      state.auth.storage && state.auth.storage.storagePlan
        ? state.auth.storage.storagePlan.accessData
        : { limit: 0, limitIn: "" },
  }));
  //   const { accessData } = storagePlan;
  const storage = accessData ? sizeToBytes(accessData.limit, accessData.limitIn) : 0;
  //   console.log({ storagePlan, storage, useStorage, totalSize });

  const [alertDialog, setAlertDialog] = useState(false);

  const onHandleAgree = () => {
    // dispatch(showLoader());
    // dispatch(deleteTransfers({ transferId, queryInfo }));
  };
  const AlertProps = {
    open: alertDialog,
    title: "You're out of storage?",
    handleClose: () => {
      setAlertDialog(false);
    },
    handleAgree: onHandleAgree,
  };
  return (
    <>
      <div className="flex  w-full items-end justify-end bg-bb py-2">
        <Button
          color="lightBlue"
          buttonType="submit"
          size="sm"
          ripple="dark"
          // disabled={count > 0 ? false : true}
          className="w-full"
          onClick={() => {
            if (storage >= useStorage + totalSize) {
              submitNow();
            } else {
              setAlertDialog(true);
            }
          }}
        >
          <span className="   font-medium text-xl capitalize">Transfer Now</span>{" "}
          <SendIcon fontSize="small" />
        </Button>
      </div>

      <AlertStorageDialog>
        <div
          {...AlertProps}
        >{`You can’t upload any more Files or Folders. Manually delete Files or Folders taking up storage.`}</div>
      </AlertStorageDialog>
    </>
  );
};

export default SendButton;
