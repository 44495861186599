import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { setFileData } from "../../appRedux/actions/dropZone";
import JSZip from "jszip";
import throttle from "lodash.throttle";
import { saveAs } from "file-saver";

import async from "async";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { WindowDimensions } from ".";
import { sizeToBytes } from "../../utils/fileConstant";
import AlertPermissionDialog from "../AlertPermissionDialog";

export default function DropZoneArea({ display }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height } = WindowDimensions();
  const [active, setActive] = useState(false);

  // useSelector(())

  const { totalSize, storagePlan, useStorage, accessData } = useSelector((state) => ({
    totalSize: state.dropZone.totalSize,
    useStorage: state.auth.storage ? state.auth.storage.useStorage : 0,
    storagePlan: state.auth.storage ? state.auth.storage.storagePlan : {},
    accessData:
      state.auth.storage && state.auth.storage.storagePlan
        ? state.auth.storage.storagePlan.accessData
        : { limit: 0, limitIn: "" },
  }));
  //   const { accessData } = storagePlan;
  const storage = accessData ? sizeToBytes(accessData.limit, accessData.limitIn) : 0;

  useEffect(() => {
    //console.log({ display });
    if (!display) {
      const timeout = setTimeout(() => {
        setActive(display);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      setActive(display);
    }
  }, [display]);

  const onDrop = useCallback((acceptedFiles) => {
    var result = acceptedFiles.reduce(function (r, a) {
      let url = a.path.split("/");
      let fileName = url.length > 1 ? url[1] : a.path;
      r[fileName] = r[fileName] || [];
      r[fileName].push(a);
      return r;
    }, Object.create(null));
    let uploadDataSize = 0;
    Object.keys(result).map((info) => {
      let isFolder = info.split(".").length > 1 ? false : true;
      let fileInfo = isFolder ? {} : result[info][0];
      let size = isFolder
        ? result[info].reduce((accumulator, object) => {
            return accumulator + object.size;
          }, 0)
        : fileInfo.size;

      uploadDataSize = uploadDataSize + size;
    });
    const takenSize = uploadDataSize + totalSize + useStorage;
    console.log("storage", "----33--");
    console.log("storage", "----33--", { result });
    // console.log({ uploadDataSize, storage, takenSize });
    // if (storage >= takenSize) {
    async.mapSeries(
      Object.keys(result),
      function (info, next) {
        let isFolder = info.split(".").length > 1 ? false : true;
        let fileInfo = isFolder ? {} : result[info][0];
        let size = isFolder
          ? result[info].reduce((accumulator, object) => {
              return accumulator + object.size;
            }, 0)
          : fileInfo.size;

        const setName = new Date().getTime() + "_" + Math.random().toString().split(".").pop();
        if (isFolder) {
          // console.log({ result: result[info] });
          onZip(result[info], function (err, data) {
            console.log({ err, data }, "storage--");
            if (err) {
              next(err);
            } else {
              next(null, {
                name: info,
                type: "Folder",
                size: size,
                // bucket: Math.random(),
                alteredName: `${setName}/${info}.zip`,
                files: new File([data], `${setName}/${info}.zip`, {
                  type: "Folder",
                }),
                numberOfFile: result[info].length,
              });
            }
          });
        } else {
          next(null, {
            name: info,
            type: fileInfo.type,
            size: size,
            // bucket: Math.random(),
            alteredName: `${setName}/${info}}.${info.split(".").pop()}`,
            files: new File([result[info][0]], `${setName}/${info}`, {
              type: fileInfo.type,
            }),
            numberOfFile: result[info].length,
          });
        }
      },
      function (err, results) {
        if (err) {
          setAlertDialog(true);
          console.log({ err }, "111--err");
          // The requested file could not be read, typically due to permission problems using Drag & drop. Please use the select process.
        } else {
          dispatch(setFileData(results));
          navigate("/transferit");
        }
      }
    );
    // } else {
    //   console.log("storage full", "----33--");
    //   console.log({ uploadDataSize });
    // }
  }, []);

  const [progress, setProgress] = useState(-1);

  const onZipUpdate = (metadata) => {
    setProgress(metadata.percent);
    // //console.log("progression: " + metadata.percent.toFixed(2) + " %");
    if (metadata.currentFile) {
      // //console.log("current file = " + metadata.currentFile);
    }
  };
  const throttledZipUpdate = throttle(onZipUpdate, 50);

  const onZip = function onZip(fileList, callback) {
    const zip = new JSZip();
    const files = Array.from(fileList);
    // setFiles(inputRef.current.files);
    files.forEach((file) => {
      // console.log({ file });
      zip.file(file.path || file.webkitRelativePath, file);
    });
    zip
      .generateAsync({ type: "blob" }, throttledZipUpdate)
      .then(function (content) {
        // saveAs(content, "files.zip");
        callback(null, content);

        // const formData = new FormData();
        // formData.append("folderzip", content);
        // //console.log("ready to send to server", content);
      })
      .catch((e) => callback(e));
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    noKeyboard: true,
    noClick: true,
  });
  const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : "";

  const [alertDialog, setAlertDialog] = useState(false);

  const onHandleAgree = () => {
    // dispatch(showLoader());
    // dispatch(deleteTransfers({ transferId, queryInfo }));
  };
  const AlertProps = {
    open: alertDialog,
    title: "Due to permission problems?",
    handleClose: () => {
      setAlertDialog(false);
    },
    handleAgree: onHandleAgree,
  };
  return (
    <div
      className={`app-full ${active ? " active" : ""}`}
      style={active ? { height: height, display: "block" } : { height: height, display: "none" }}
    >
      <div
        {...getRootProps({
          className: `dropzone-full ${isDragActive ? "active" : ""} ${additionalClass} `,
        })}
        // style={display ? { height: height } : { height: 0 }}
        style={active ? { height: height, display: "block" } : { height: height, display: "none" }}
      >
        {" "}
        <span className="py-20">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            style={{ fontSize: "150px", width: "100%" }}
          >
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
          </svg>
        </span>
        <p className="py-10 text-sx">Drag & drop files here.</p>
      </div>
      <AlertPermissionDialog>
        <div
          {...AlertProps}
        >{`Drag & Drop requested file could not be read,  typically due to permission problems. Please use click to select folder.`}</div>
      </AlertPermissionDialog>
    </div>
  );
}
