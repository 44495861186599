import { AUTH_INIT_STATE } from "./auth";
import { USER_UPDATE_SUCCESS } from "../actionTypes/user";
import {
  SIGNOUT_AUTH_MEMBER_SUCCESS,
  MEMBER_CONTACT_US,
  MEMBER_CONTACT_US_SUCCESS,
} from "../actionTypes/auth";
export const INIT_STATE = {
  member: AUTH_INIT_STATE.member,
  contactStatus: false,
};

function MemberReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return {
        member: {},
      };
    }
    case USER_UPDATE_SUCCESS: {
      //console.log({ payload }, "USER_UPDATE_SUCCESS");
      return {
        ...state,
        member: { ...payload.data },
      };
    }

    case MEMBER_CONTACT_US_SUCCESS: {
      return {
        ...state,
        contactStatus: false,
      };
    }
    case MEMBER_CONTACT_US: {
      return {
        ...state,
        contactStatus: true,
      };
    }
    default:
      return state;
  }
}

export default MemberReducer;
