import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "../appRedux/actions/common";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertSnackbar({ children }) {
  const dispatch = useDispatch();
  const { showMessage, messageStatus, message } = useSelector((state) => ({
    showMessage: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
  }));
  React.useEffect(() => {
    if (showMessage) {
      const timeout = setTimeout(() => {
        dispatch(hideMessage());
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showMessage]);
  // console.log({ showMessage, messageStatus, message, children });
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={showMessage}
        autoHideDuration={600}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // onClose={handleClose}
      >
        {showMessage ? (
          <Alert severity={messageStatus} sx={{ width: "100%" }}>
            {message}
          </Alert>
        ) : (
          ""
        )}
      </Snackbar>
      {/* {children} */}
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
}
