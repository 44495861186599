import {
  TRANSFER_CREATE_SUCCESS,
  TRANSFER_LIST,
  TRANSFER_LIST_SUCCESS,
  TRANSFER_INFO_SUCCESS,
  TRANSFER_FILTER_HANDLING,
  TRANSFER_SEARCH_NAME,
  TRANSFER_UPDATE_SUCCESS,
  TRANSFER_SELECTED,
  TRANSFER_SELECTED_TUCHED,
  TRANSFER_SELECTED_RESET,
  TRANSFER_SEARCH_NAME_CLEAR,
  TRANSFER_RECEIVED,
  TRANSFER_BUCKET_STATUS,
  TRANSFER_DETAILS_INFO_SUCCESS,
  TRANSFER_DELETE_SUCCESS,
  TRANSFER_CENCEL,
} from "../actionTypes/transfer";

const INIT_STATE = {
  transfer: [],
  transferList: [],
  selectedList: [],
  selectedData: {
    values: { contactIds: [], subject: "", message: "", transferData: [] },
    errors: { contactIds: "", subject: "", message: "", transferData: "" },
    touched: {
      contactIds: false,
      subject: false,
      message: false,
      transferData: false,
    },
  },
  isSuccess: false,
  transferInfo: {},
  transferDetailsInfo: {},
  queryInfo: {
    page: 0,
    limit: 10,
    // sent: true,
    received: false,
  },
  totalRecords: 0,
  page: 0,
  columnHandling: [],
  searchName: "",
  searchStatus: false,
  transferType: "Active",
  isUpdate: false,
  isTransfer: true,
  setBucketData: false,
  transferSuccessInfo: {},
  columnHandling: [
    {
      id: "SendTo",
      key: "sendTo",
      label: "Send To",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Subject",
      key: "subject",
      label: "Subject",
      align: "left",
      // pading: "0 3",
      visibility: true,
      isFilter: true,
    },

    {
      id: "Downloaded",
      key: "downloaded",
      label: "Downloaded",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "ReceivedAt",
      key: "createdAt",
      label: "Sent At",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Files",
      key: "totalFiles",
      label: "Files",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Size",
      key: "totalSize",
      // childKey: "contactNumber",
      label: "Size",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Status",
      key: "expireAt",
      label: "Status",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    // {
    //   id: "Status",
    //   key: "active",
    //   label: "Status",
    //   align: "center",
    //   visibility: true,
    //   isFilter: true,
    // },
    {
      id: "Action",
      key: "_id",
      label: "Action",
      align: "center",
      visibility: true,
      isFilter: true,
    },
  ],
  columnHandlingReceived: [
    {
      id: "SenderEmail",
      key: "senderInfo",
      childKey: "email",
      label: "Sender Email",
      align: "left",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Subject",
      key: "subject",
      label: "Subject",
      align: "left",
      // pading: "0 3",
      visibility: true,
      isFilter: true,
    },
    {
      id: "ReceivedAt",
      key: "createdAt",
      label: "Received At",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Files",
      key: "totalFiles",
      label: "Files",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Size",
      key: "totalSize",
      // childKey: "contactNumber",
      label: "Size",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    {
      id: "Status",
      key: "expireAt",
      label: "Status",
      align: "center",
      visibility: true,
      isFilter: true,
    },
    // {
    //   id: "Status",
    //   key: "active",
    //   label: "Status",
    //   align: "center",
    //   visibility: true,
    //   isFilter: true,
    // },
    {
      id: "Action",
      key: "_id",
      label: "Action",
      align: "center",
      visibility: true,
      isFilter: true,
    },
  ],
  transferCancel: false,
};

function transferReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    // type: ,
    // payload: "transferData",
    case TRANSFER_SELECTED_TUCHED: {
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          touched: {
            ...state.selectedData.touched,
            transferData: payload === "transferData" ? true : false,
            // contactIds: payload === "contactIds" ? true : false,
            // subject: payload === "subject" ? true : false,
            // message: payload === "message" ? true : false,
          },
          errors: {
            ...state.selectedData.errors,
            transferData: payload === "transferData" ? "" : "",
            // contactIds: payload === "contactIds" ? true : false,
            // subject: payload === "subject" ? true : false,
            // message: payload === "message" ? true : false,
          },
        },
      };
    }
    case TRANSFER_BUCKET_STATUS: {
      //console.log({ payload });
      return {
        ...state,
        setBucketData: payload.status,
        transferSuccessInfo: payload.info,
      };
    }
    case TRANSFER_LIST: {
      //console.log({ payload });
      return {
        ...state,
        transfer: payload.data,
        isTransfer: false,
      };
    }
    case TRANSFER_SELECTED: {
      return {
        ...state,
        selectedData: { ...state.selectedData, ...payload },
      };
    }
    case TRANSFER_DELETE_SUCCESS: {
      return {
        ...state,
        transferCancel: false,
      };
    }
    case TRANSFER_CENCEL: {
      return {
        ...state,
        transferCancel: true,
        setBucketData: false,
      };
    }
    case TRANSFER_SELECTED_RESET: {
      return {
        ...state,
        selectedData: {
          values: {
            contactIds: [],
            subject: "",
            message: "",
            transferData: [],
          },
          errors: {
            contactIds: "",
            subject: "",
            message: "",
            transferData: "",
          },
          touched: {
            contactIds: false,
            subject: false,
            message: false,
            transferData: false,
          },
        },
      };
    }
    case TRANSFER_LIST_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        searchStatus: false,
        transferList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.totalRecords,
        isSuccess: false,
        // page: payload.queryInfo.page || 0
      };
    }

    case TRANSFER_CREATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        transferList: [payload.data, ...state.transferList],
        isSuccess: true,
        transferSuccessInfo: payload.data,
        setBucketData: true,
      };
    }

    case TRANSFER_UPDATE_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        transferList: state.transferList.map(
          (obj) => (payload.data._id === obj._id ? payload.data : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        transferInfo: payload.data,
        isSuccess: true,
        isUpdate: true,
      };
    }

    case TRANSFER_INFO_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        transferInfo: payload.data,
        // isSuccess: true
      };
    }
    case TRANSFER_DETAILS_INFO_SUCCESS: {
      //console.log({ payload });
      return {
        ...state,
        transferDetailsInfo: payload.data,
        // isSuccess: true
      };
    }
    case TRANSFER_FILTER_HANDLING: {
      //console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        transferType: payload.transferType,
      };
    }
    case TRANSFER_SEARCH_NAME: {
      //console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: {
          page: 0,
          limit: state.queryInfo.limit,
          received: state.queryInfo.received,
        },
      };
    }
    case TRANSFER_RECEIVED: {
      //console.log({ payload });
      return {
        ...state,
        queryInfo: {
          page: 0,
          limit: state.queryInfo.limit,
          received: payload,
        },
      };
    }

    case TRANSFER_SEARCH_NAME_CLEAR: {
      //console.log({ payload });
      return {
        ...state,
        searchName: "",
        searchStatus: false,
        transferList: [],
      };
    }
    default:
      return state;
  }
}

export default transferReducer;
