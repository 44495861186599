export const USER_CONTACT_CREATE = "USER_CONTACT_CREATE";
export const USER_CONTACT_CREATE_SUCCESS = "USER_CONTACT_CREATE_SUCCESS";
export const USER_CONTACT_CREATE_ERROR = "USER_CONTACT_CREATE_ERROR";
export const USER_CONTACT_LIST = "USER_CONTACT_LIST";
export const USER_CONTACT_LIST_SUCCESS = "USER_CONTACT_LIST_SUCCESS";
export const USER_CONTACT_LIST_ERROR = "USER_CONTACT_LIST_ERROR";
export const USER_CONTACT_INFO = "USER_CONTACT_INFO";
export const USER_CONTACT_INFO_SUCCESS = "USER_CONTACT_INFO_SUCCESS";
export const USER_CONTACT_INFO_ERROR = "USER_CONTACT_INFO_ERROR";
export const USER_CONTACT_UPDATE_SUCCESS = "USER_CONTACT_UPDATE_SUCCESS";
export const USER_CONTACT_UPDATE_ERROR = "USER_CONTACT_UPDATE_ERROR";
export const USER_CONTACT_DELETE_SUCCESS = "USER_CONTACT_DELETE_SUCCESS";
export const USER_CONTACT_FILTER_HANDLING = "USER_CONTACT_FILTER_HANDLING";
export const USER_CONTACT_SEARCH_NAME = "USER_CONTACT_SEARCH_NAME";
export const USER_CONTACT_SELECTED = "USER_CONTACT_SELECTED";
export const USER_CONTACT_SELECTED_CONTACT = "USER_CONTACT_SELECTED_CONTACT";
export const USER_CONTACT_SELECTED_TUCHED = "USER_CONTACT_SELECTED_TUCHED";
export const USER_CONTACT_EDIT_TABLE = "USER_CONTACT_EDIT_TABLE";
export const USER_CONTACT_SELECTED_SET_NEW_VALUE = "USER_CONTACT_SELECTED_SET_NEW_VALUE";
