import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Alert,
} from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import { Icon, Button } from "@material-tailwind/react";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AlertStorageDialog({ children }) {
  //console.log({ children });
  const { props } = children;
  const { open, title, description, footer, handleClose, handleAgree } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-storage-dialog-slide-title"
        aria-describedby="alert-storage-dialog-slide-description"
      >
        <DialogTitle id="alert-storage-dialog-slide-title" color="error">
          {/* <Alert severity="error"> */}
          {title}
          {/* </Alert> */}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="inherit">
            Cancel
          </Button>
          {/* <Button onClick={() => handleAgree()} color="error">
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
