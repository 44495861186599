import React, { useEffect, useState } from "react";
import Icon from "@material-tailwind/react/Icon";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import { removeObjectDropZone } from "../../appRedux/actions/dropZone";
import { Pagination } from "@material-ui/lab";
import usePagination from "../common/Pagination";
import {
  getFileType,
  bytesToSize,
  getTotalSize,
} from "../../utils/fileConstant";
import useWindowDimensions from "../common/WindowDimensions";

const DataList = ({ fileListInfo, _DATA }) => {
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  // var textLength = width > 400 ? 20 : 15;
  const [textLength, setTextLength] = useState(20);
  useEffect(() => {
    setTextLength(width > 480 ? 20 : width > 440 ? 16 : width > 400 ? 16 : 15);
  }, [width]);

  return (
    <>
      <div className="flex flex-wrap  ">
        {_DATA.currentData().map((info, index) => {
          let isFolder =
            info.type === "Folder" || info.type === "folder" ? true : false;
          let fileInfo = isFolder ? {} : info;
          var status = isFolder
            ? { type: "folder", icon: "folder" }
            : getFileType(info);

          const size = info.size;
          return (
            <div
              key={index + "DataList-2"}
              className={`  w-full md:w-6/12  px-2 py-0 `}
            >
              <Button
                color="transparent"
                className=" text-xl   text-black capitalize w-full  px-1 py-2 item-start justify-start  text-left w-full bg-white rounded-xl overflow-hdden shadow-md mb-0"
                size="2xl"
                buttonType="button"
                ripple="light"
              >
                <span
                  className={`${status.family ? "px-2" : "px-1"}`}
                  id={status.family ? "imageIcon" : ""}
                >
                  <Icon
                    family={status.family}
                    name={isFolder ? "folder" : status.icon}
                    size="2xl"
                    color="black"
                  />
                </span>
                <span
                  className="   font-medium text-sm "
                  style={{ textTransform: "none" }}
                >
                  {info.name.length > textLength
                    ? info.name.slice(0, textLength) + "..."
                    : info.name}
                </span>
                <span className="text-gray-600 px-2 text-xs  cursor-pointer">
                  ({bytesToSize(size)})
                </span>
              </Button>
              <span
                className="text-gray-600 px-1 text-xs float-right cursor-pointer"
                onClick={() => dispatch(removeObjectDropZone(info))}
                style={{ position: "relative", top: "-32px", right: "7px" }}
              >
                <Icon name={"close"} color="text-gray-800" />
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DataList;
