import React from "react";

import { Pagination } from "@material-ui/lab";
const paginationView = ({ count, page, handleChange }) => {
  // console.log({ count }, "paginationView");
  return (
    <Pagination
      count={count}
      // size="large"
      page={page}
      variant="outlined"
      color="primary"
      onChange={handleChange}
    />
  );
};

export default paginationView;
