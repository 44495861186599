// import { SIGNIN_MEMBER_ERROR, SIGNIN_MEMBER_SUCCESS } from '../actionTypes/auth';
import { SIGNOUT_AUTH_MEMBER_SUCCESS } from "../actionTypes/auth";
import { TRANSFER_SELECTED_RESET } from "../actionTypes/transfer";

import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_COMPOSE_STATUS,
  SET_PROGRESS,
  SET_FILE_STATUS,
  SET_DOWNLOAD,
  SET_UUID,
  SET_DEFAULT_PROGRESS,
  SET_PROGRESS_2,
  SET_PROGRESS_STATUS,
  SET_DEFAULT_PROGRESS_STATUS,
} from "../actionTypes/common";

const INIT_STATE_COMMON = {
  loader: false,
  download: false,
  showMessage: false,
  messageStatus: "",
  message: "",
  memberMessage: "",
  initURL: "",
  showCompose: false,
  modelCompose: false,
  progress: 0,
  progressEvent: {
    total: 0,
    loaded: 0,
    timeStamp: 0,
    type: "",
    progress: 0,
    totalLoaded: 0,
    totalTimeStamp: 0,
  },
  progressStatus: { index: 0, success: {}, error: "" },
  totalSize: 0,
  loadedFileSize: 0,
  loadedSize: 0,
  numberOfFile: 0,
  UUID: "",
  uploadData: {},
};

function CommonReducer(state = INIT_STATE_COMMON, action) {
  const { type, payload } = action;
  // console.log({ type, payload }, "----------CommonReducer----------");
  switch (type) {
    case SIGNOUT_AUTH_MEMBER_SUCCESS: {
      return { ...INIT_STATE_COMMON };
    }
    case TRANSFER_SELECTED_RESET: {
      return {
        ...state,
        progress: 0,
        totalSize: 0,
        loadedFileSize: 0,
        loadedSize: 0,
        numberOfFile: 0,
        progressEvent: {
          total: 0,
          loaded: 0,
          timeStamp: 0,
          type: "",
          progress: 0,
          totalLoaded: 0,
          totalTimeStamp: 0,
        },
      };
    }
    case SET_DOWNLOAD: {
      return {
        ...state,
        download: payload,
      };
    }
    case SET_UUID: {
      return {
        ...state,
        UUID: payload,
      };
    }

    case SET_PROGRESS: {
      let totalLoaded = state.progressEvent.totalLoaded;
      const { totalSize, timeStamp, type, curTotal, uploadProgress } = payload;
      let uploadData = state.uploadData;
      let sLoaded;
      if (uploadData[uploadProgress.key]) {
        const { total, loaded, isTrusted } = uploadProgress;
        if (isTrusted) {
        }
        sLoaded = loaded > 0 ? loaded + totalLoaded : totalLoaded;

        if (loaded > 0 && total === loaded) {
          totalLoaded = loaded + totalLoaded;
        }
        uploadData[uploadProgress.key] = uploadProgress;
      } else {
        const { total, loaded, isTrusted } = uploadProgress;
        if (isTrusted) {
        }
        sLoaded = loaded > 0 ? loaded + totalLoaded : totalLoaded;

        if (loaded > 0 && total === loaded) {
          totalLoaded = loaded + totalLoaded;
        }
        uploadData[uploadProgress.key] = uploadProgress;
      }
      const progress = Math.round((sLoaded / totalSize) * 100);

      return {
        ...state,
        progressEvent: {
          ...state.progressEvent,
          ...payload,
          loaded: sLoaded,
          totalLoaded,
          progress,
          total: totalSize,
        },
      };
    }

    case SET_PROGRESS_2: {
      const { loaded, timeStamp } = payload;
      const totalLoaded = state.progressEvent.totalLoaded + loaded;
      const totalTimeStamp = state.progressEvent.totalTimeStamp + timeStamp;

      return {
        ...state,
        progressEvent: { ...state.progressEvent, totalLoaded, totalTimeStamp },
      };
    }
    case SET_DEFAULT_PROGRESS: {
      return {
        ...state,
        progressEvent: {
          ...state.progressEvent,
          total: 0,
          loaded: 0,
          timeStamp: 0,
          type: "",
          progress: 0,
          totalLoaded: 0,
          totalTimeStamp: 0,
        },
        uploadData: {},
      };
    }
    case SET_PROGRESS_STATUS: {
      return {
        ...state,
        progressStatus: { ...state.progressStatus, ...payload },
      };
    }
    case SET_DEFAULT_PROGRESS_STATUS: {
      return {
        ...state,
        progressStatus: { index: 0, success: {}, error: "" },
      };
    }

    // case SET_PROGRESS: {
    //   //console.log({ state, payload });
    //   // var loaded =
    //   //   payload.numberOfFile !== state.numberOfFile
    //   //     ? state.loadedSize + state.loadedFileSize + payload.loaded
    //   //     : state.loadedSize + payload.loaded;
    //   var progress = state.progress;
    //   if (payload.progress && payload.progress > state.progress) {
    //     progress = payload.progress;
    //   } else if (payload.loaded) {
    //     progress = Math.round((payload.loaded / state.totalSize) * 100);
    //   }
    //   return {
    //     ...state,
    //     progress: progress,
    //     // loadedSize: loaded,
    //     loadedFileSize: payload.loaded ? payload.loaded : state.totalSize,
    //   };
    // }
    case SET_FILE_STATUS: {
      return {
        ...state,
        loadedSize: state.loadedFileSize + state.loadedSize,
        totalLoaded: payload.totalLoaded ? payload.totalLoaded : state.totalLoaded,
        totalSize: payload.totalSize ? payload.totalSize : state.totalSize,
        numberOfFile: payload.numberOfFile ? payload.numberOfFile : state.numberOfFile,
      };
    }

    case ON_COMPOSE_STATUS: {
      return {
        ...state,
        showCompose: payload.showCompose,
        modelCompose: payload.modelCompose,
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: payload,
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        // showMessage: false,
      };
    }

    case ON_SHOW_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: payload.error ? "error" : "success",
        message: payload.memberMessage,
      };
    }
    case ON_HIDE_MESSAGE: {
      return {
        ...state,
        loader: false,
        showMessage: false,
      };
    }

    default:
      return state;
  }
}

export default CommonReducer;
