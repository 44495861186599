import { STORAGE_INFO_SUCCESS } from "../actionTypes/storage";
import { ON_SHOW_MESSAGE, INIT_URL, ON_SHOW_LOADER } from "../actionTypes/common";
import { memberSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

export const getStorageInfo = (paramsObj) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/user/storage/info", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      localStorage.setItem("storagePlan", JSON.stringify(data.data));
      dispatch({
        type: STORAGE_INFO_SUCCESS,
        payload: data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        memberSignOut({
          changeMessage: true,
          payload: {
            memberMessage: response.data.memberMessage,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          memberMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
