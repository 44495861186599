import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
// import TagFacesIcon from "@material-ui/icons/TagFaces";
import { TextField } from "@material-ui/core";
import { Label } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { selectedDataUserContacts } from "../../../appRedux/actions/userContacts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    borderRadius: "0.75rem",
    // minHeight: "100px",
    marginBottom: "10px",
    flexWrap: "wrap",
    // padding: theme.spacing(0, 0, 0),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  chip: {
    // maxWidth: CHIP_MAX_WIDTH,
    margin: "4px 6px 4px 6px",
    fontSize: "12px",
  },
}));

export default function SubjectValue() {
  const classes = useStyles();
  const { transferInfo } = useSelector((state) => ({
    transferInfo: state.transfer.transferInfo,
  }));
  return (
    <>
      <Paper className={`${classes.root} `}>
        <Label color={"black"}>Subject</Label>
        <div className="mb-1 mt-0 mx-4 bg-bb w-full pt-2 pb-4" id="removed">
          <p>{transferInfo.subject}</p>
          {/* <TextField
            className="text-black"
            id="standard-multiline-static"
            multiline
            type="text"
            variant="standard"
            fullWidth
            size="small"
            value={transferInfo.subject}
            minRows={2}
            maxRows={2}
            max={40}
            readOnly={true}
            disabled
          /> */}
        </div>
      </Paper>
    </>
  );
}
