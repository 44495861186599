import H5 from "@material-tailwind/react/Heading5";
import LeadText from "@material-tailwind/react/LeadText";
import Icon from "@material-tailwind/react/Icon";
import { Link } from "react-router-dom";
import { CardImage, Image } from "@material-tailwind/react";

import Logo from "../../assets/img/logo-black-1600x400.png";

export default function DefaultFooter() {
  var tlJsHost =
    window.location.protocol == "https:"
      ? "https://secure.trust-provider.com/"
      : "http://www.trustlogo.com/";
  var locationUrl = window.location;
  const yearOf = new Date().getFullYear().toString();
  return (
    <>
      <footer className="relative bg-gray-100 pt-8 pb-6">
        <div className="container max-w-7xl mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left pt-6">
            <div className="w-full lg:w-6/12 px-4">
              {/* <H5 color="gray">
                {" "} */}
              <CardImage
                src={Logo}
                className=" pt-10"
                style={{ boxShadow: "none", width: "200px" }}
              />
              {/* </H5> */}
              <div className="-mt-4">
                <LeadText color="blueGray">
                  No need to worry about your email inbox space anymore, we're
                  here to help you.
                </LeadText>
              </div>
              <a
                href={`${tlJsHost}ttb_searcher/trustlogo?v_querytype=W&v_shortname=POSDV&v_search=${locationUrl}&x=6&y=5`}
                // onMouseOver={(event) =>
                //   window.tLeB(
                //     event,
                //     tlJsHost +
                //       "ttb_searcher/trustlogo?v_querytype=C&v_shortname=POSDV&v_search=" +
                //       locationUrl +
                //       "&x=6&y=5",
                //     "tl_popupPOSDV"
                //   )
                // }
                // onMouseMove={(e) => window.tLXB(e)}
                // onMouseOut={(e) => window.tLTC("tl_popupPOSDV")}
                // onDragStart={() => {
                //   return false;
                // }}
              >
                <img
                  src="https://www.positivessl.com/images/seals/positivessl_trust_seal_sm_124x32.png"
                  border="0"
                  // onMouseDown={(event) => {
                  //   return window.tLKB(
                  //     event,
                  //     tlJsHost +
                  //       "ttb_searcher/trustlogo?v_querytype=W&v_shortname=POSDV&v_search=" +
                  //       locationUrl +
                  //       "&x=6&y=5"
                  //   );
                  // }}
                  // onContextMenu={(event) => {
                  //   return window.tLLB(event);
                  // }}
                />
              </a>
              {/* <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center">
                
                 
                <a
                  href="https://www.youtube.com/channel"
                  className="grid place-items-center bg-white text-red-600 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon family="font-awesome" name="fab fa-youtube" />
                </a>
                <a
                  href="https://github.com"
                  className="grid place-items-center bg-white text-gray-900 shadow-md font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon family="font-awesome" name="fab fa-github" />
                </a>
              </div> */}
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top">
                <div className="w-full lg:w-4/12 px-4 ml-auto md:mb-0 mb-8">
                  <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                    Quick Link
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/home"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 ">
                  <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/frequently-asked-questions"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        FAQ
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/help"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Help Us
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/privacy-policy "
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-conditions"
                        rel="noreferrer"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Terms &amp; Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-300" />

          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-6/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-700 font-medium py-1">
                Copyright ©{" "}
                {yearOf == "2022" ? yearOf : "2022-" + yearOf.slice(2)}{" "}
                TransferItNow by{" "}
                <a
                  href="http://www.gaviralgamtec.com/"
                  target="_blank"
                  className="text-gray-700 hover:text-gray-900 transition-all"
                >
                  Gaviral Gamtec Private Limited
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
