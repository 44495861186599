import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import { Icon } from "@iconify/react";
// import searchFill from "@iconify/icons-eva/search-fill";
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  // Button,
  Toolbar,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import {
  getTransfers,
  onChangeSearchName,
} from "../../appRedux/actions/transfer";
import { Icon, Button } from "@material-tailwind/react";
import { onClearSearchName } from "../../appRedux/actions/transfer";

// ----------------------------------------------------------------------
// import { FilterSidebar } from ".";
// import {
//   getLearnerList,
//   onChangeSearchName,
// } from "../../../../appRedux/actions/learner";

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 450px)": {
    display: "grid",
    height: 140,
    justifyContent: "center",
  },
  // padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 360 },
  "& fieldset": {
    borderWidth: `1px !important`,
  },
  "@media (max-width: 400px)": {
    width: 260,
    "&.Mui-focused": { width: 260 },
    "& fieldset": {
      borderWidth: `1px !important`,
    },
  },
  // "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  // "& fieldset": {
  //   borderWidth: `1px !important`,
  //   borderColor: `${theme.palette.grey["500_32"]} !important`,
  // },
}));

// ----------------------------------------------------------------------

export default function ListToolbar() {
  const dispatch = useDispatch();

  // page: 0,
  // limit: 10,
  // received: false,
  const { searchName, queryInfo, searchStatus, received } = useSelector(
    (state) => ({
      searchName: state.transfer.searchName,
      searchStatus: state.transfer.searchStatus,
      queryInfo: state.transfer.queryInfo,
      received: state.transfer.queryInfo.received,
    })
  );
  useEffect(() => {
    if (searchStatus) {
      //console.log({ queryInfo });
      const timeout = setTimeout(() => {
        dispatch(
          getTransfers({
            ...queryInfo,
            searchText: searchName !== "" ? searchName : undefined,
          })
        );
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchName, searchStatus]);
  // useEffect(()=>{

  // },[received])

  const [openFilter, setOpenFilter] = useState(false);
  return (
    <>
      <RootStyle id="SearchStyle">
        <SearchStyle
          sx={{ minWidth: 300 }}
          value={searchName}
          onChange={(event) => {
            dispatch(onChangeSearchName(event.target.value));
          }}
          placeholder="Search by email and subject"
          name=""
          startAdornment={
            <InputAdornment position="start">
              <Box
                // component={Icon}
                // icon={searchFill}
                sx={{ color: "text.disabled" }}
              />
            </InputAdornment>
          }
        />
        <br />
        <div className="flex  w-max gap-4">
          <Button
            className="mx-2 border border-light-blue-500 capitalize"
            buttonType={!received ? "filled" : "link"}
            onClick={() => {
              //console.log({ received }, "Sent");
              if (received) {
                dispatch(onClearSearchName());
                dispatch(getTransfers({ page: 0, limit: 10, received: false }));
              }
            }}
          >
            Sent&nbsp; <Icon name={"forward_to_inbox"} />
          </Button>
          <Button
            className="mx-2 border border-light-blue-500 capitalize"
            buttonType={received ? "filled" : "link"}
            onClick={() => {
              //console.log({ received }, "Received");
              if (!received) {
                dispatch(onClearSearchName());
                dispatch(getTransfers({ page: 0, limit: 10, received: true }));
              }
            }}
          >
            Received&nbsp; <Icon name={"move_to_inbox"} />
          </Button>
        </div>
      </RootStyle>

      {/* <FilterSidebar
        isOpenFilter={openFilter}
        onCloseFilter={() => setOpenFilter(false)}
      /> */}
    </>
  );
}
