import Header from "../../components/common/Header";
import HelpView from "../../components/InformedPage/help";

export default function Help() {
  return (
    <main>
      <Header pageName="Help Us" />
      <section className="relative mt-30 py-16  bg-gray-100">
        <div className="container max-w-7xl px-4 mx-auto">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-2xl -mt-64">
            <div className="px-6">
              <HelpView />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
